import { ButtonProps, Text, Flex } from "@chakra-ui/react";
import { useAddRecentTransaction } from "@rainbow-me/rainbowkit";
import { Button } from "components/common/Button/Button";
import { IGame } from "interfaces/IGame";
import { useContext } from "react";
import { EtherContext } from "services/etherProvider";
import { UserContext } from "services/storageGamesProvider";

export const KillButton = ({
  gameData,
  reloadGame,
  large,
  ...rest
}: ButtonProps & {
  gameData: IGame;
  reloadGame: () => void;
  large?: boolean;
}) => {
  const { played } = useContext(UserContext);
  const { etherHelper } = useContext(EtherContext);
  const addRecentTransaction = useAddRecentTransaction();

  const killGame = () => {
    etherHelper
      ?.send("killGame", {
        _gameId: gameData?.gameId,
      })
      .then((hash: any) => {
        addRecentTransaction({
          hash: hash,
          description: "Killed game #" + gameData?.gameId,
        });
        reloadGame();
      });
  };
  if (gameData.currentRound.tickets === 0) {
    return (
      <Button
        colorScheme="red"
        onClick={() => {
          killGame();
        }}
        {...rest}
      >
        <Flex
          justifyContent="center"
          gap="3"
          alignItems="center"
          direction="column"
        >
          <Text wordBreak="break-all">Kill Game</Text>
        </Flex>
      </Button>
    );
  } else {
    return <></>;
  }
};
