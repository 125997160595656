import { Flex, Text, VStack } from "@chakra-ui/react";
import { EstimateRoundDuration } from "components/common/EstimateDuration/EstimateRoundDuration";
import { EstimateTicketIncrementDuration } from "components/common/EstimateDuration/EstimateTicketIncrementDuration";
import { useContext } from "react";
import { GameContext } from "services/gameProvider";
import { useBlockNumber } from "wagmi";

export const RoundTimer = () => {
  const { game } = useContext(GameContext);

  const { data: currentBlock, isLoading } = useBlockNumber({ watch: true });
  return (
    <VStack>
      {!isLoading && currentBlock != null && game && (
        <>
          <Text>
            {!game?.active
              ? "GAME FINISHED"
              : game?.currentRound.expired
              ? "ROUND ENDED"
              : game.currentRound.startedAt == 0 ||
                game.currentRound.startedAt > (currentBlock ? currentBlock : 0)
              ? ""
              : Number(
                  BigInt(game?.currentRound.expiresAt) - currentBlock!
                ).toString() +
                " blocks until Round " +
                game?.currentRound.roundId +
                " end "}
          </Text>
          <Flex gap="4">
            {game.ticketPriceIncrementStep > 0 &&
              game.currentRound.startedAt != 0 &&
              game.currentRound.startedAt <=
                (currentBlock ? currentBlock : 0) && (
                <VStack>
                  <Text as="b">Ticket Price</Text>
                  <EstimateTicketIncrementDuration layout="fancy"></EstimateTicketIncrementDuration>
                </VStack>
              )}
            <VStack>
              <Text as="b">Round</Text>
              <EstimateRoundDuration layout="fancy"></EstimateRoundDuration>
            </VStack>
          </Flex>
        </>
      )}
    </VStack>
  );
};
