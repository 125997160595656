// Importa le dipendenze necessarie
import { createContext, useContext, useEffect, useState } from "react";
import { Metadata, NostrProvider, useNostr } from "nostr-react";

import {
  Box,
  Text,
  useDisclosure,
  Flex,
  Spinner,
  Tooltip,
  Link,
} from "@chakra-ui/react";
import { ChatIcon, CloseIcon } from "@chakra-ui/icons"; // Supponendo che tu voglia usare l'icona della chat fornita da Chakra UI
import { Button } from "../Button/Button";
import { Card } from "../Card/Card";
import { ChatBox } from "./ChatBox";
import { ChatHeader } from "./ChatHeader";
import { NostrLogin } from "./NostrLogin";
import { NostrUserContext, useNostrUser } from "./NostrUserContext";
import { BiCircle } from "react-icons/bi";
import { BsCCircleFill, BsCircleFill } from "react-icons/bs";
import { motion } from "framer-motion";

export interface Message {
  message: string;
  sender?: string;
  created: number;
  pubkey: string;
  profile?: any;
}
const relays = ["wss://relay2.nostrchat.io", "wss://relay1.nostrchat.io"];

function Trollbox() {
  return (
    <NostrProvider relayUrls={relays} debug={false}>
      <NostrUserContext.Provider value={useNostrUser()}>
        <Chat></Chat>
      </NostrUserContext.Provider>
    </NostrProvider>
  );
}

function Chat() {
  const { isOpen, onToggle } = useDisclosure();
  const { pubkey } = useContext(NostrUserContext);
  const nostr = useNostr();

  return (
    <Box
      position="fixed"
      bottom={0}
      right={0}
      m={{ base: 0, md: 4 }}
      w={{ base: "100%", md: "500px" }}
      as={Flex}
      justifyContent="right"
    >
      {/* Trigger the chat dialog */}
      <Button
        onClick={onToggle}
        borderRadius="full"
        aria-label={""}
        hidden={isOpen}
      >
        <ChatIcon />
      </Button>
      {/* Window of the chat */}
      <Card
        hidden={!isOpen}
        p={4}
        borderRadius="md"
        boxShadow="md"
        mt={4}
        w="full"
        as={motion.div}
        initial={{ scaleY: [0] }}
        animate={{ scaleY: 1 }}
        exit={{ scaleY: 0 }}
        transition="3s"
      >
        <Flex justifyContent="space-between" alignItems="center" my="1">
          <Text as="b"> TrollBox</Text>
          <Flex gap="1" alignItems="center">
            <RelayStatus></RelayStatus>
            <Button size="xs">
              <CloseIcon onClick={onToggle}></CloseIcon>
            </Button>
          </Flex>
        </Flex>
        {!pubkey ? (
          <NostrLogin></NostrLogin>
        ) : pubkey && !nostr.isLoading ? (
          <Flex direction="column" gap="2">
            <ChatHeader />
            <ChatBox></ChatBox>
          </Flex>
        ) : (
          <Spinner></Spinner>
        )}
        <Box as={Flex} justifyContent="right">
          <Link
            href={
              "https://www.nostrchat.io/channel/" +
              process.env.REACT_APP_CHAT_CHANNEL
            }
          >
            Powered by <b>Nostr</b>
          </Link>
        </Box>
      </Card>
    </Box>
  );
}

export function RelayStatus() {
  const nostr = useNostr();

  return relays.map((r, i) => {
    const connectedRelay = nostr.connectedRelays.find((re) => re.url === r);

    let rel = { status: 2, url: r };
    return (
      <Tooltip
        key={i}
        placement="top"
        hasArrow
        label={
          rel.url + "\n " + (connectedRelay ? "connected" : "disconnected")
        }
      >
        <Box>
          {!connectedRelay ? (
            <BsCircleFill color="gray"></BsCircleFill>
          ) : connectedRelay.status == 0 ? (
            <BsCircleFill color="red"></BsCircleFill>
          ) : (
            connectedRelay.status == 1 && (
              <BsCircleFill color="green"></BsCircleFill>
            )
          )}
        </Box>
      </Tooltip>
    );
  });
}

export default Trollbox;
