import { EstimateDuration } from "./EstimateDuration";

export const EstimateTicketIncrementDuration = ({
  layout,
}: {
  layout: "fancy" | "mini" | "normal";
}) => {
  return (
    <EstimateDuration
      layout={layout}
      color="multi"
      calculateDelta={(g, b) => {
        return b
          ? g.ticketPriceIncrementStep -
              ((Number(b) - g.currentRound.startedAt) %
                g.ticketPriceIncrementStep)
          : 0;
      }}
      calculateTotal={(g) => {
        return g ? g.ticketPriceIncrementStep : 0;
      }}
    ></EstimateDuration>
  );
};
