import { Box, ColorModeScript, Flex } from "@chakra-ui/react";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { ChakraProvider } from "@chakra-ui/react";
import Fonts, { theme } from "components/Theme";
import "./styles/_globals.scss";
import { WagmiConfig } from "wagmi";
import { RainbowKitProvider, lightTheme } from "@rainbow-me/rainbowkit";
import { wagmiConfig, chains, supportedChains } from "services/wallets";
import "./styles/_globals.scss";
import HomePage from "pages";
import { HashRouter, Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import Dapp from "pages/dapp";
import { Layout } from "components/layout";
import Info from "pages/info";
import Game from "pages/game";

const pj = require("../package.json");
console.log(pj.version);

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

const rainbowTheme = {
  ...lightTheme({
    borderRadius: "none",
    fontStack: "system",
    overlayBlur: "small",
  }),
  shadows: {
    connectButton: "none",
    dialog: "none",
    profileDetailsAction: "none",
    selectedOption: "none",
    selectedWallet: "none",
    walletLogo: "none",
  },
  colors: {
    accentColor: "var(--chakra-colors-blue-500)",
    accentColorForeground: "var(--chakra-colors-white)",
    actionButtonBorder: "var(--chakra-colors-black)",
    actionButtonBorderMobile: "var(--chakra-colors-black)",
    actionButtonSecondaryBackground: "var(--chakra-colors-blue-400)",
    closeButton: "var(--chakra-colors-white)",
    closeButtonBackground: "var(--chakra-colors-blue-500)",
    connectButtonBackground: "var(--chakra-colors-blue-500)",
    connectButtonBackgroundError: "var(--chakra-colors-red-500)",
    connectButtonInnerBackground: "var(--chakra-colors-blue-500)",
    connectButtonText: "var(--chakra-colors-white)",
    connectButtonTextError: "var(--chakra-colors-black)",
    connectionIndicator: "var(--chakra-colors-white)",
    downloadBottomCardBackground: "var(--chakra-colors-white)",
    downloadTopCardBackground: "var(--chakra-colors-white)",
    error: "var(--chakra-colors-red-500)",
    generalBorder: "var(--chakra-colors-black)",
    generalBorderDim: "2px",
    menuItemBackground: "var(--chakra-colors-white)",
    modalBackdrop: "var(--chakra-colors-alpha)",
    modalBackground: "var(--chakra-colors-white)",
    modalBorder: "var(--chakra-colors-black)",
    modalText: "var(--chakra-colors-black)",
    modalTextDim: "18px",
    modalTextSecondary: "var(--chakra-colors-gray-600)",
    profileAction: "var(--chakra-colors-white)",
    profileActionHover: "var(--chakra-colors-gray-100)",
    profileForeground: "var(--chakra-colors-white)",
    selectedOptionBorder: "var(--chakra-colors-black)",
    standby: "var(--chakra-colors-gray-600)",
  },
};
root.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider
        chains={chains}
        initialChain={supportedChains[0]}
        theme={rainbowTheme}
        showRecentTransactions={true}
        coolMode
      >
        <ChakraProvider theme={theme}>
          <ColorModeScript />

          <Fonts />

          <HashRouter basename="/">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route
                path="/dapp"
                element={
                  <Layout>
                    <Dapp />
                  </Layout>
                }
              />

              <Route
                path="/info"
                element={
                  <Layout isInfo>
                    <Info />
                  </Layout>
                }
              />
              <Route
                path="/game/:gameId"
                element={
                  <Layout>
                    <Game />
                  </Layout>
                }
              />
            </Routes>
          </HashRouter>
          <Box bottom={0} p="2" as={Flex} justifyContent="flex-start" w="full">
            <pre>v{pj.version}</pre>
          </Box>
        </ChakraProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
