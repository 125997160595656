// Importa le dipendenze necessarie
import { useContext, useRef, useState } from "react";

import {
  Box,
  VStack,
  IconButton,
  InputGroup,
  InputRightElement,
  Flex,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { BiSend } from "react-icons/bi";
import { useAccount } from "wagmi";
import { MessageElement } from "./ChatMessageElement";
import { dateToUnix, useNostr, useNostrEvents } from "nostr-react";
import { Event as NostrEvent, getEventHash, getSignature } from "nostr-tools";
import { NostrUserContext } from "./NostrUserContext";
import { Message } from ".";

const BUFFER_CHAT_LENGTH = 100;

export function ChatBox() {
  const { seckey, pubkey } = useContext(NostrUserContext);
  const { address } = useAccount();
  const [messagesMap, setMessagesMap] = useState<{
    [key: `${string}`]: Message;
  }>({});

  const [messagesArray, setMessagesArray] = useState<Message[]>([]);
  const nostr = useNostr();
  const bottomRef = useRef(null);

  const [message, setMessage] = useState("");
  const { events, onDone, onEvent } = useNostrEvents({
    filter: {
      kinds: [42, 43],
      "#e": [process.env.REACT_APP_CHAT_CHANNEL as string],
    },
  });

  //   const encrypt = (payload: any) => {
  //     return nip44.encrypt(
  //       hexStringToUint8Array(process.env.REACT_APP_CHAT_PRIVATE_KEY as string),
  //       JSON.stringify(payload)
  //     );
  //   };
  //   const decrypt = (payload: string) => {
  //     return JSON.parse(
  //       nip44.decrypt(
  //         hexStringToUint8Array(process.env.REACT_APP_CHAT_PRIVATE_KEY as string),
  //         payload
  //       )
  //     );
  //   };
  const reloadSortedChat = (buff: any) => {
    const arr = Object.values(messagesMap).sort((a, b) => {
      return a.created - b.created;
    });
    setMessagesArray(arr);
  };

  const addEventToMessageMap = (e: any, buffer: { [key: string]: Message }) => {
    if (buffer[e.id] === undefined) {
      if (e.kind === 42) {
        let keysArr = Object.keys(buffer);
        if (keysArr.length >= BUFFER_CHAT_LENGTH) {
          delete buffer[keysArr[0]];
        }
        let address;
        if (e.tags.length > 1) address = e.tags[1][1];

        buffer[e.id] = {
          message: e.content,
          sender: address,
          pubkey: e.pubkey,
          created: e.created_at,
        };
      } else if (e.kind === 43) {
        delete buffer[e.tags[0][1]];
      }
    }
  };

  onDone(() => {
    let buffer = messagesMap;

    events.forEach((e) => {
      console.log(e);

      addEventToMessageMap(e, buffer);
    });
    setMessagesMap(buffer);
    reloadSortedChat(buffer);
  });
  onEvent((e) => {
    console.log(e);

    let buffer = messagesMap;
    if (buffer[e.id] === undefined) {
      addEventToMessageMap(e, buffer);
      setMessagesMap(buffer);
      reloadSortedChat(buffer);
    }
  });

  const handleSendMessage = () => {
    if (message.length > 0) {
      const event: NostrEvent & any = {
        content: message,
        kind: 42,
        tags: [
          [
            "e",
            process.env.REACT_APP_CHAT_CHANNEL,
            nostr.connectedRelays[0].url,
            "root",
          ],
        ],
        created_at: dateToUnix(),
        pubkey: pubkey!,
      };
      if (address != null) {
        event.tags.push(["ewallet", address]);
      }

      event.id = getEventHash(event);
      event.sig = getSignature(event, seckey!);

      nostr.publish(event);
      setMessage("");
    }
  };

  return (
    <Box h={["300px", "500px"]}>
      <Flex direction="column" h="full">
        <VStack
          align="stretch"
          flexGrow={1}
          overflowY="scroll"
          gap="0"
          scrollSnapAlign="end"
          scrollBehavior="smooth"
        >
          {messagesArray.map((m, i) => {
            return <MessageElement key={i} message={m}></MessageElement>;
          })}
          <Box my="5" ref={bottomRef}></Box>
        </VStack>

        {seckey ? (
          <InputGroup size="md" mt={4}>
            <Textarea
              disabled={seckey?.length === 0}
              rows={2}
              onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
              placeholder="Type your message"
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
            <InputRightElement>
              <IconButton
                disabled={seckey?.length === 0}
                aria-label="Send"
                variant="outline"
                shadow="none"
                onClick={handleSendMessage}
              >
                <BiSend />
              </IconButton>
            </InputRightElement>
          </InputGroup>
        ) : (
          <Text>
            Only private key login and generated keys are supported for write
          </Text>
        )}
      </Flex>
    </Box>
  );
}
