import { IGame } from "../interfaces/IGame"
import { createContext, useCallback, useEffect, useState } from "react"
import { EtherHelper } from "./etherProvider"




const compare = (o1:any,o2:any)=>{ 
    if(o2==null || o1==null){
        return false;
    }
    for(var p in o1){
        if(o1.hasOwnProperty(p)){

            if(    o1[p] !== o2[p]){
                 
                if(typeof o1[p] == "object"){
                    if(!compare(o1[p], o2[p])){
                        return false
                    }
                }
                    
                else 
                    return false
            }
        }
    }
    
    return true;
}

export const GameContext = createContext<{ game: IGame | null, reloadGame: () => void }>({ game: null, reloadGame: () => { } })
export const GameProvider = (game:IGame|null=null,gameId: number, etherHelper?: EtherHelper) => {

    const [gameData, setGameData] = useState<IGame | null>(game)
    const reloadGame = useCallback(() => {

        if(!Number.isNaN(gameId)){
            if(gameData ==null || gameData.active){
                etherHelper?.call("getGame", { _gameId: gameId }).then(g => { 
               

                
                    if (!compare(g,gameData) ) { 
                        setGameData(g)
    
                    }
        
                })
            }
        
        }
       
    }, [etherHelper, gameId])

    useEffect(() => {   

        if(game==null){
            reloadGame()

        }
    }, [])

    return {
        game: gameData,
        reloadGame: reloadGame
    }
}