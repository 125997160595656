import { Tooltip } from "@chakra-ui/react";
import { BsInfoCircle } from "react-icons/bs";
export const EstimationInfo = ({
  networkSpeed,
  ...rest
}: {
  networkSpeed: number;
}) => (
  <>
    <Tooltip
      label={"Avg. time per block: " + networkSpeed.toFixed(3) + "s"}
      placement="top"
      hasArrow
    >
      <span>
        <BsInfoCircle color="blue" {...rest}></BsInfoCircle>
      </span>
    </Tooltip>
  </>
);
