import { Box, Flex } from "@chakra-ui/layout";
import { IGame } from "interfaces/IGame";
import { BsCircleFill } from "react-icons/bs";
import { ChakraProps } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { GameContext } from "services/gameProvider";
import { useBlockNumber } from "wagmi";

export const GameStatusIcon = ({ ...rest }) => {
  const { game } = useContext(GameContext)
  const number = useBlockNumber()
  const [color, setColor] = useState("grey.400");
  useEffect(() => {
    if (!game || game.currentRound.startedAt == 0 || game.currentRound.startedAt > (number.data ? number.data : 0))
      setColor("gray.300")
    else if (!game?.active)
      setColor("red.500")

    else if (game?.currentRound.expired)
      setColor("yellow.500")
    else
      setColor("green.500")
  }, [game])
  return game && <Box
    as={Flex}
    alignItems="center"
    color={color
    }
  >
    <BsCircleFill {...rest}></BsCircleFill>
  </Box>

}
