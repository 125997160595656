import {
  Flex,
  Text,
  Tr,
  Td,
  Tooltip,
  Skeleton,
  useBreakpointValue,
  Box,
} from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { EtherContext, EtherHelper } from "services/etherProvider";
import { EstimateRoundDuration } from "components/common/EstimateDuration/EstimateRoundDuration";
import { GameStatusIcon } from "components/game/GameStatusIcon";
import { BsBarChartFill } from "react-icons/bs";
import { BiInfinite } from "react-icons/bi";
import { GameTags } from "components/game/GameTags";
import { EstimateTicketIncrementDuration } from "components/common/EstimateDuration/EstimateTicketIncrementDuration";
import { formatMoney } from "utils/utils";
import { AvaxIcon } from "components/common/CustomIcons/AvaxIcon";
import { GameContext, GameProvider } from "services/gameProvider";
import { useAnimate, motion } from "framer-motion";
import { IGame } from "interfaces/IGame";
import { useBlockNumber } from "wagmi";
import { StarredGameButton } from "components/common/StarGameButton";
import { UserContext } from "services/storageGamesProvider";
import { BuyButton } from "components/common/GameButtons/BuyButton";
import { EndButton } from "components/common/GameButtons/EndButton";
import { Link } from "react-router-dom";
import { PriceConversion } from "components/common/PriceConversion";

export const GameListElement = ({
  game,
  tableColumnProps,
}: {
  game: IGame | number;
  tableColumnProps: any;
}) => {
  const { etherHelper } = useContext(EtherContext);

  const { played } = useContext(UserContext);
  const blocknumber = useBlockNumber();
  const { game: lineGame, reloadGame } =
    typeof game == "number"
      ? GameProvider(null, game, etherHelper)
      : GameProvider(game, game.gameId, etherHelper);
  const { data } = useBlockNumber();
  // const scope = useRef(null)
  const [scope, animate] = useAnimate();
  const compressedTable = useBreakpointValue({ base: true, md: false });
  useEffect(() => {
    if (lineGame != null) {
      if (scope.current != null) {
        animate(scope.current, {
          background: [
            "#BEE3F822",
            "#BEE3F877",
            "#BEE3F8aa",
            "#BEE3F877",
            "#BEE3F822",
            "#BEE3F800",
          ],
          transition: { duration: 1 },
        });
      }
    }
  }, [animate, lineGame]);

  useEffect(() => {
    if (data != etherHelper?.getLastBlockNumber()) {
      reloadGame();
    }
  }, [data]);

  return (
    <GameContext.Provider value={{ game: lineGame, reloadGame }}>
      {!compressedTable ? (
        <Tr as={motion.tr} ref={scope}>
          <>
            <FirstCells
              tableColumnProps={tableColumnProps}
              lineGame={lineGame}
            ></FirstCells>
            <LastCells
              tableColumnProps={tableColumnProps}
              lineGame={lineGame}
              blocknumber={blocknumber}
              reloadGame={reloadGame}
            ></LastCells>
          </>
        </Tr>
      ) : (
        <>
          <Tr as={motion.tr} ref={scope} borderTop="black 2px solid">
            <FirstCells
              tableColumnProps={tableColumnProps}
              lineGame={lineGame}
              compressed={true}
            ></FirstCells>
          </Tr>
          <Tr borderBottom="black 2px solid">
            <LastCells
              tableColumnProps={tableColumnProps}
              lineGame={lineGame}
              compressed={true}
              blocknumber={blocknumber}
              reloadGame={reloadGame}
            ></LastCells>
          </Tr>
        </>
      )}
    </GameContext.Provider>
  );
};
interface GameTableCellProps {
  tableColumnProps: any;
  lineGame: IGame;
}

const FirstCells = ({
  tableColumnProps,
  lineGame,
  compressed,
}: {
  tableColumnProps: any;
  lineGame: IGame | null;
  compressed?: boolean;
}) => {
  return lineGame ? (
    <>
      <Td {...tableColumnProps.id}>
        <Flex gap={2} alignItems="center" alignContent="center">
          <Box flexGrow="1">
            <GameID
              tableColumnProps={tableColumnProps}
              lineGame={lineGame}
            ></GameID>
          </Box>

          <GameStatus
            tableColumnProps={tableColumnProps}
            lineGame={lineGame}
          ></GameStatus>
        </Flex>
      </Td>
      <Td {...tableColumnProps.tags}>
        <Tags tableColumnProps={tableColumnProps} lineGame={lineGame}></Tags>
      </Td>
      <Td {...tableColumnProps.jackpot}>
        <Jackpot
          tableColumnProps={tableColumnProps}
          lineGame={lineGame}
        ></Jackpot>{" "}
      </Td>
    </>
  ) : (
    <>
      <Td {...tableColumnProps.id}>
        <Skeleton h="24px" w="full"></Skeleton>
      </Td>
      <Td {...tableColumnProps.tags}>
        <Skeleton h="24px" w="full"></Skeleton>
      </Td>
      <Td {...tableColumnProps.jackpot}>
        <Skeleton h="24px" w="full"></Skeleton>
      </Td>
      {!compressed && (
        <Td {...tableColumnProps.rounds}>
          <Skeleton h="24px" w="full"></Skeleton>
        </Td>
      )}
    </>
  );
};

const LastCells = ({
  tableColumnProps,
  lineGame,
  blocknumber,
  reloadGame,
  compressed,
}: {
  tableColumnProps: any;
  lineGame: IGame | null;

  reloadGame: () => void;
  blocknumber: any;
  compressed?: boolean;
}) => {
  return lineGame ? (
    !compressed ? (
      <>
        <Td {...tableColumnProps.timer}>
          <RoundTimer
            tableColumnProps={tableColumnProps}
            lineGame={lineGame}
          ></RoundTimer>
        </Td>
        <Td {...tableColumnProps.price}>
          <TicketPrice
            tableColumnProps={tableColumnProps}
            lineGame={lineGame}
            blocknumber={blocknumber}
          ></TicketPrice>
        </Td>
        <Td {...tableColumnProps.tickets}>
          <Tickets
            tableColumnProps={tableColumnProps}
            lineGame={lineGame}
          ></Tickets>
        </Td>
        <Td {...tableColumnProps.actions}>
          <Actions
            tableColumnProps={tableColumnProps}
            lineGame={lineGame}
            blocknumber={blocknumber}
            reloadGame={reloadGame}
          ></Actions>
        </Td>
      </>
    ) : (
      <>
        <Td>
          <RoundTimer
            tableColumnProps={tableColumnProps}
            lineGame={lineGame}
          ></RoundTimer>
        </Td>
        <Td>
          <Flex alignItems="center" alignContent="center" gap="1">
            <Text size="sm"> Ticket Price:</Text>
            <TicketPrice
              tableColumnProps={tableColumnProps}
              lineGame={lineGame}
              blocknumber={blocknumber}
            ></TicketPrice>
          </Flex>
        </Td>

        <Td>
          <Actions
            tableColumnProps={tableColumnProps}
            lineGame={lineGame}
            blocknumber={blocknumber}
            reloadGame={reloadGame}
          ></Actions>
        </Td>
      </>
    )
  ) : (
    <>
      <Td {...tableColumnProps.timer}>
        <Skeleton h="24px" w="full"></Skeleton>
      </Td>
      <Td {...tableColumnProps.price}>
        <Skeleton h="24px" w="full"></Skeleton>
      </Td>
      <Td {...tableColumnProps.tickets}>
        <Skeleton h="24px" w="full"></Skeleton>
      </Td>
      <Td {...tableColumnProps.actions}>
        <Skeleton h="40px" w="full"></Skeleton>
      </Td>
    </>
  );
};

const GameID = ({ tableColumnProps, lineGame }: GameTableCellProps) => (
  <Tooltip placement="top" hasArrow label="Go in the game details">
    <span>
      <Link to={"/game/" + lineGame?.gameId}>
        <Text
          as="b"
          cursor="pointer"
          fontSize="1.4rem"
          textDecoration="underline"
        >
          #{lineGame?.gameId}
        </Text>
      </Link>
    </span>
  </Tooltip>
);

const Tags = ({ tableColumnProps, lineGame }: GameTableCellProps) => (
  <Flex gap={2} alignContent="center" alignItems="center">
    <StarredGameButton gameId={lineGame.gameId}></StarredGameButton>

    <GameTags></GameTags>
  </Flex>
);

const Jackpot = ({ tableColumnProps, lineGame }: GameTableCellProps) => (
  <Flex direction="column" gap="1">
    <Flex gap="1">
      {formatMoney(EtherHelper.fromWei(lineGame.currentRound.currentBalance))}
      <AvaxIcon></AvaxIcon>
    </Flex>
    <PriceConversion
      value={lineGame.currentRound.currentBalance}
    ></PriceConversion>
  </Flex>
);

const GameStatus = ({ tableColumnProps, lineGame }: GameTableCellProps) => (
  <Flex gap={1} alignContent="center" alignItems="center">
    <GameStatusIcon></GameStatusIcon>

    <Text>{lineGame.currentRound.roundId}</Text>
    <Text>/</Text>
    <Text>{lineGame.maxRounds > 0 ? lineGame.maxRounds : <BiInfinite />}</Text>
  </Flex>
);

const TicketPrice = ({
  tableColumnProps,
  lineGame,
  blocknumber,
}: GameTableCellProps & {
  blocknumber: any;
}) => (
  <Flex direction="column" gap={1}>
    <Flex gap="1" alignItems="center">
      {lineGame.ticketPriceIncrementStep > 0 && (
        <>
          {!lineGame.currentRound.expired &&
          lineGame.currentRound.startedAt != 0 &&
          lineGame.currentRound.startedAt <=
            (blocknumber && blocknumber?.data ? blocknumber?.data : 0) ? (
            <EstimateTicketIncrementDuration layout="mini"></EstimateTicketIncrementDuration>
          ) : (
            <Tooltip label="Price increment game" placement="top" hasArrow>
              <span>
                <BsBarChartFill></BsBarChartFill>
              </span>
            </Tooltip>
          )}
        </>
      )}
      <Text>
        {formatMoney(EtherHelper.fromWei(lineGame.currentTicketPrice))}
      </Text>
      <AvaxIcon></AvaxIcon>
    </Flex>
    <PriceConversion value={lineGame.currentTicketPrice}></PriceConversion>
  </Flex>
);

const RoundTimer = ({ tableColumnProps, lineGame }: GameTableCellProps) => (
  <EstimateRoundDuration></EstimateRoundDuration>
);
const Tickets = ({ tableColumnProps, lineGame }: GameTableCellProps) => (
  <Text>{lineGame.currentRound.tickets}</Text>
);

const Actions = ({
  tableColumnProps,
  lineGame,
  blocknumber,
  reloadGame,
}: GameTableCellProps & {
  reloadGame: () => void;

  blocknumber: any;
}) => (
  <Flex w="full" gap={2}>
    {lineGame.active &&
      (lineGame.currentRound.expired ? (
        <EndButton gameData={lineGame} reloadGame={reloadGame}></EndButton>
      ) : (
        lineGame.currentRound.startedAt <= blocknumber.data! && (
          <BuyButton gameData={lineGame} reloadGame={reloadGame}></BuyButton>
        )
      ))}
  </Flex>
);
