import { IGame } from "interfaces/IGame";
import { useContext, useEffect, useState } from "react";
import { EtherContext, EtherHelper } from "services/etherProvider";
import { Flex, Text } from "@chakra-ui/react";
import { Card } from "components/common/Card/Card";
import { formatMoney } from "utils/utils";
import Marquee from "react-fast-marquee";
import { PLAYED_KEY, useStorageGames } from "services/storageGamesProvider";
import { useAccount, useBlockNumber } from "wagmi";
import { useHouseRulesProvider } from "services/houserulesProvider";
import { usePriceProvider } from "services/priceProvder";
import { PriceConversion } from "components/common/PriceConversion";

export const MarqueeGameInfos = ({ game }: { game: IGame | null }) => {
  const [nextRoundProjInfo, setNextRoundProjInfo] = useState<{
    status: string;
    creatorReward?: number;
    id?: number;
    houseReward?: number;
    winnerReward?: number;
    nextPot?: number;
    nextRoundBalance: number;
  } | null>(null);
  const { etherHelper } = useContext(EtherContext);
  const { address } = useAccount();
  const played = useStorageGames(PLAYED_KEY);
  const { houseRules } = useHouseRulesProvider(etherHelper);
  const blockNumber = useBlockNumber();
  const { priceFeed } = usePriceProvider();
  useEffect(() => {
    if (game && houseRules) {
      let _nextRoundBalance = 0;
      let _isHouseGame =
        process.env.REACT_APP_HOUSE_ADDRESS?.toLowerCase() ==
        game.creator.toLowerCase();

      var nextRound: any = {
        status: "continue",
      };
      const toNum = (bn: bigint) => {
        return (_nextRoundBalance = parseFloat(EtherHelper.fromWei(bn)));
      };
      if (game.currentRound.tickets > 0) {
        let _endBalance = toNum(game.currentRound.currentBalance);

        nextRound.endBalance = _endBalance;
        let _houseReward =
          (toNum(game.currentRound.currentBalance) / 100) *
          +houseRules.houseFee;
        nextRound.houseReward = _houseReward;

        let _creatorReward = _isHouseGame
          ? 0
          : (toNum(game.currentRound.currentBalance) / 100) *
            +houseRules.creatorFee;
        nextRound.creatorReward = _creatorReward;

        let _nextPot =
          game.currentRound.roundId + 1 <= game.maxRounds || game.maxRounds == 0
            ? (_endBalance / 100) * +houseRules.nextPotFee
            : 0;
        nextRound.nextPot = _nextPot;

        let _winnerReward =
          _endBalance - (_houseReward + _creatorReward + _nextPot);
        nextRound.winnerReward = _winnerReward;

        let _remaining =
          _endBalance -
          _winnerReward -
          (_houseReward + _creatorReward + _nextPot);
        _nextRoundBalance = _nextPot; // pay the winner
      } else {
        _nextRoundBalance = parseFloat(
          EtherHelper.fromWei(game.currentRound.currentBalance)
        );
      }
      nextRound.nextRoundBalance = _nextRoundBalance;

      nextRound.id = game.currentRound.roundId + 1;
      if (
        ((_nextRoundBalance < houseRules.minTicketPrice ||
          nextRound.nextPot < houseRules.minPotBalanceForNewGames) &&
          !_isHouseGame &&
          game.currentRound.tickets > 0) ||
        (game.currentRound.roundId + 1 >= game.maxRounds && game.maxRounds != 0)
      ) {
        nextRound.status = "ended";
        // end expired s.games
      }
      setNextRoundProjInfo(nextRound);
    }
  }, [game, houseRules]);
  return (
    <Card mb={3}>
      <Marquee speed={50} pauseOnClick gradient gradientWidth="200">
        {!game?.active ? (
          <Text colorScheme="danger" mx="8" as="b">
            Game is finished
          </Text>
        ) : blockNumber?.data &&
          game.currentRound.startedAt >= blockNumber.data ? (
          <Text color="blue" mx="8" as="b">
            Game will start at #{game.currentRound.startedAt}
          </Text>
        ) : blockNumber?.data && game.currentRound.startedAt == 0 ? (
          <Text color="blue" mx="8" as="b">
            Game will start with the first ticket buyed
          </Text>
        ) : (
          nextRoundProjInfo && (
            <Flex justifyContent="space-around" w="full" gap="3">
              {nextRoundProjInfo.status == "ended" ? (
                <Text color="red" mx="8" as="b">
                  Game will finish this round
                </Text>
              ) : (
                <Text>Next round {nextRoundProjInfo.id}</Text>
              )}
              {game.currentRound.tickets < 1 &&
                nextRoundProjInfo.status != "ended" && (
                  <Text color="darkorange" mx="8" as="b">
                    No one have played this round
                  </Text>
                )}
              {nextRoundProjInfo.status != "ended" && (
                <Flex mx="8" as={Text} gap={1}>
                  Next Jackpot
                  <b>{formatMoney(nextRoundProjInfo?.nextRoundBalance)}</b>
                  <PriceConversion
                    value={nextRoundProjInfo?.nextRoundBalance}
                  ></PriceConversion>
                </Flex>
              )}

              {nextRoundProjInfo?.winnerReward && (
                <Flex mx="8" as={Text} gap={1}>
                  Winner reward:
                  <b>{formatMoney(nextRoundProjInfo?.winnerReward)}</b>
                  <PriceConversion
                    value={nextRoundProjInfo?.winnerReward}
                  ></PriceConversion>
                </Flex>
              )}
              {nextRoundProjInfo?.creatorReward && (
                <Flex mx="8" as={Text} gap={1}>
                  Creator reward:
                  <b>{formatMoney(nextRoundProjInfo?.creatorReward)}</b>
                  <PriceConversion
                    value={nextRoundProjInfo?.creatorReward}
                  ></PriceConversion>
                </Flex>
              )}
              {nextRoundProjInfo?.houseReward && (
                <Flex mx="8" as={Text} gap={1}>
                  House reward:
                  <b>{formatMoney(nextRoundProjInfo?.houseReward)}</b>
                  <PriceConversion
                    value={nextRoundProjInfo?.houseReward}
                  ></PriceConversion>
                </Flex>
              )}
            </Flex>
          )
        )}
      </Marquee>
    </Card>
  );
};
