// Importa le dipendenze necessarie
import { useContext, useEffect, useState } from "react";

import { Box, Input, Flex, Spinner } from "@chakra-ui/react";
import { Button } from "../Button/Button";
import { generatePrivateKey, getPublicKey, nip19 } from "nostr-tools";

import { NostrUserContext } from "./NostrUserContext";
import { useMessageHandler } from "utils/useMessageHandler";
export function NostrLogin() {
  const [pubkey, setPubKey] = useState("");
  const [seckey, setSecKey] = useState("");
  const { update } = useContext(NostrUserContext);
  const [loading, setLoading] = useState<boolean>(false);
  const { invoke } = useMessageHandler();

  useEffect(() => {
    console.log(loading);
  }, [loading]);
  const handleLogin = (how?: string) => {
    setLoading(true);
    switch (how) {
      case "ext":
        handleLoginWithExtension();
        break;
      case "pubk":
        handleLoginWithPubKey();
        break;
      case "pk":
        handleLoginWithSecKey();
        break;
      default:
        handleGenerateKey();
    }
    setLoading(false);
  };
  const handleLoginWithExtension = async () => {
    try {
      if ((window as any).nostr?.getPublicKey !== undefined) {
        const pubkey = await (window as any).nostr.getPublicKey();

        update({ pubkey });
      }
    } catch (err: any) {
      invoke({ message: err.message }, "error");
    }
  };

  const handleLoginWithPubKey = () => {
    try {
      const pub = pubkey.startsWith("npub")
        ? nip19.decode(pubkey).data.toString()
        : pubkey;
      update({ pubkey: pub });
    } catch (err: any) {
      invoke({ message: err.message }, "error");
    }
  };

  const handleLoginWithSecKey = () => {
    try {
      const sec = seckey.startsWith("nsec")
        ? nip19.decode(seckey).data.toString()
        : seckey;
      console.log(sec);
      update({
        pubkey: getPublicKey(sec),
        seckey: sec,
      });
    } catch (err: any) {
      invoke({ message: err.message }, "error");
    }
  };

  const handleGenerateKey = () => {
    console.log(loading);
    let sec = generatePrivateKey();

    update({ pubkey: getPublicKey(sec), seckey: sec });
  };

  return (
    <Box>
      <Flex direction="column" gap={4}>
        {!loading ? (
          <>
            {" "}
            <Button
              onClick={() => {
                handleLogin("ext");
              }}
              disabled={(window as any).nostr?.getPublicKey === undefined}
            >
              Login with Extension
            </Button>
            <Input
              placeholder="Public Key"
              value={pubkey}
              onChange={(e) => setPubKey(e.target.value)}
            />
            <Button
              onClick={() => {
                handleLogin("pubk");
              }}
            >
              Login with Public Key
            </Button>
            <Input
              placeholder="Secret Key"
              value={seckey}
              onChange={(e) => setSecKey(e.target.value)}
            />
            <Button
              onClick={() => {
                handleLogin("pk");
              }}
            >
              Login with Secret Key
            </Button>
            <Button
              onClick={() => {
                handleLogin();
              }}
            >
              Generate Key
            </Button>
          </>
        ) : (
          <Spinner></Spinner>
        )}
      </Flex>
    </Box>
  );
}
