import {
  Flex,
  Text,
  FormControl,
  FormHelperText,
  NumberInput,
  NumberInputStepper,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInputField,
  SimpleGrid,
  Switch,
  Box,
  Table,
  Tbody,
  Td,
  Tr,
  Thead,
  Th,
  FormLabel,
} from "@chakra-ui/react";
import { useContext } from "react";
import { BiInfinite } from "react-icons/bi";
import { formatMoney, toHHMMSS } from "utils/utils";
import { HouseRulesContext } from "services/houserulesProvider";
import { AvaxIcon } from "components/common/CustomIcons/AvaxIcon";
import { EstimationInfo } from "components/common/EstimationInfo";
import { PriceConversion } from "components/common/PriceConversion";

export const CreateNewGameForm = ({
  activeStep,
  networkSpeed,
  gameRules,
  setGameRules,
  ...rest
}: {
  activeStep: number;
  networkSpeed: number;
  gameRules: any;
  setGameRules: (obj: any) => void;
}) => {
  const { houseRules } = useContext(HouseRulesContext);

  const updateRules = (property: any) => {
    setGameRules({ props: { ...gameRules.props, ...property } });
  };

  return (
    <Flex direction="column" gap={4}>
      <Box hidden={activeStep !== 1}>
        <SimpleGrid columns={{ base: 1, md: 2 }} gap="5">
          <FormControl
            mb="10"
            isInvalid={
              gameRules.props.ticketPrice <
              (houseRules ? houseRules.minTicketPrice : 0.005)
            }
          >
            <FormLabel htmlFor="ticketPrice">
              <Flex gap="1" w="full" justifyContent="space-between">
                <Text> Ticket Price</Text>
                <PriceConversion
                  value={gameRules.props.ticketPrice}
                ></PriceConversion>
              </Flex>
            </FormLabel>
            <NumberInput
              step={0.05}
              defaultValue={gameRules.props.ticketPrice}
              min={houseRules ? houseRules.minTicketPrice : 0.005}
              onChange={(e) => updateRules({ ticketPrice: e })}
            >
              <NumberInputField name="ticketPrice" id="ticketPrice" />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <FormHelperText>
              Ticket price for the game (min.{" "}
              {houseRules && houseRules.minTicketPrice})
            </FormHelperText>
          </FormControl>
          <FormControl
            mb="10"
            isInvalid={
              gameRules.props.gameDuration <
              (houseRules ? houseRules.minDuration : 1)
            }
          >
            <FormLabel htmlFor="gameDuration">
              <Flex justifyContent="space-between" alignItems="center">
                Round base duration
                <Flex gap="1" alignItems="center">
                  <span>~</span>
                  {toHHMMSS(gameRules.props.gameDuration * networkSpeed)}
                  <EstimationInfo networkSpeed={networkSpeed}></EstimationInfo>
                </Flex>
              </Flex>
            </FormLabel>
            <NumberInput
              step={1}
              defaultValue={gameRules.props.gameDuration}
              min={houseRules ? houseRules.minDuration : 1}
              onChange={(e) => updateRules({ gameDuration: parseInt(e) })}
            >
              <NumberInputField name="gameDuration" id="gameDuration" />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <FormHelperText>
              Value corresponding to blocks (min #
              {houseRules ? houseRules.minDuration : 1})
            </FormHelperText>
          </FormControl>
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2 }} gap="5">
          <FormControl
            mb="10"
            isInvalid={
              gameRules.props.potBalance <
              (houseRules ? houseRules.minPotBalanceForNewGames : 0.05)
            }
          >
            <FormLabel htmlFor="potBalance">
              <Flex gap="1" w="full" justifyContent="space-between">
                <Text> Pot Balance</Text>
                <PriceConversion
                  value={gameRules.props.potBalance}
                ></PriceConversion>
              </Flex>
            </FormLabel>
            <NumberInput
              step={0.05}
              defaultValue={gameRules.props.potBalance}
              min={houseRules ? houseRules.minPotBalanceForNewGames : 0.05}
              onChange={(e) => updateRules({ potBalance: e })}
            >
              <NumberInputField name="potBalance" id="potBalance" />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <FormHelperText>
              Initial Pot Balance for the game (min.{" "}
              {houseRules?.minPotBalanceForNewGames}), pot need to be greater or
              equal of ticket price
            </FormHelperText>
          </FormControl>
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2 }} gap="5">
          <FormControl mb="10" isInvalid={gameRules.props.ticketStep < 0}>
            <FormLabel htmlFor="ticketStep">
              <Flex justifyContent="space-between" alignItems="center">
                Ticket step per block
                <Flex gap="1" alignItems="center">
                  <span>~</span>
                  {toHHMMSS(gameRules.props.ticketStep * networkSpeed)}
                  <EstimationInfo networkSpeed={networkSpeed}></EstimationInfo>
                </Flex>
              </Flex>
            </FormLabel>

            <NumberInput
              step={1}
              defaultValue={gameRules.props.ticketStep}
              min={1}
              onChange={(e) => updateRules({ ticketStep: parseInt(e) })}
            >
              <NumberInputField id="ticketStep" name="ticketStep" />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <FormHelperText>
              Blocks added to round duration with a ticket purchase
            </FormHelperText>
          </FormControl>
          <FormControl mb="10" isInvalid={gameRules.props.maxStepPerBlock < 0}>
            <FormLabel htmlFor="maxStepPerBlock">
              <Flex justifyContent="space-between" alignItems="center">
                Max step per block
                <Flex gap="1" alignItems="center">
                  <span>~</span>
                  {toHHMMSS(gameRules.props.maxStepPerBlock * networkSpeed)}
                  <EstimationInfo networkSpeed={networkSpeed}></EstimationInfo>
                </Flex>
              </Flex>
            </FormLabel>
            <NumberInput
              step={1}
              defaultValue={gameRules.props.maxStepPerBlock}
              min={1}
              onChange={(e) => updateRules({ maxStepPerBlock: parseInt(e) })}
            >
              <NumberInputField id="maxStepPerBlock" name="maxStepPerBlock" />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <FormHelperText>
              Max step possible with multiple ticket purchase in same block
            </FormHelperText>
          </FormControl>
        </SimpleGrid>
      </Box>

      <Box hidden={activeStep !== 2}>
        <SimpleGrid columns={{ base: 1, md: 2 }} gap="5">
          <FormControl>
            <FormLabel htmlFor="startRoundWithTicket">
              Start game at first ticket
            </FormLabel>
            <Switch
              id="startRoundWithTicket"
              name="startRoundWithTicket"
              isChecked={gameRules.props.startRoundWithTicket}
              size="lg"
              onChange={(e) => {
                updateRules({
                  startRoundWithTicket: !gameRules.props.startRoundWithTicket,
                });
              }}
            />
          </FormControl>

          <FormControl hidden={gameRules.props.startRoundWithTicket}>
            <FormLabel htmlFor="startRoundAfter">
              <Flex justifyContent="space-between" alignItems="center">
                Delay before the game start
                <Flex gap="1" alignItems="center">
                  <span>~</span>
                  {toHHMMSS(gameRules.props.startRoundAfter * networkSpeed)}
                  <EstimationInfo networkSpeed={networkSpeed}></EstimationInfo>
                </Flex>
              </Flex>
            </FormLabel>
            <NumberInput
              step={1}
              defaultValue={gameRules.props.startRoundAfter}
              min={0}
              onChange={(e) => updateRules({ startRoundAfter: parseInt(e) })}
            >
              <NumberInputField id="startRoundAfter" name="startRoundAfter" />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <FormHelperText>
              <Flex gap={1}>
                <Text>The time is expressed in blocks</Text>
              </Flex>
            </FormHelperText>
          </FormControl>
        </SimpleGrid>
        <SimpleGrid>
          <FormControl>
            <FormLabel htmlFor="renewRoundWithGameRules">
              Renew rounds with game start rules
            </FormLabel>
            <Switch
              id="renewRoundWithGameRules"
              name="renewRoundWithGameRules"
              isChecked={gameRules.props.renewRoundWithGameRules}
              size="lg"
              onChange={(e) => {
                updateRules({
                  renewRoundWithGameRules:
                    !gameRules.props.renewRoundWithGameRules,
                });
              }}
            />
          </FormControl>
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2 }} gap="5">
          <FormControl>
            <FormLabel htmlFor="maxRounds">Max Rounds</FormLabel>
            <Switch
              id="maxRounds"
              name="maxRounds"
              isChecked={gameRules.props.maxRoundsEnable}
              size="lg"
              onChange={(e) => {
                updateRules({
                  maxRoundsEnable: !gameRules.props.maxRoundsEnable,
                });
              }}
            />
          </FormControl>

          <FormControl
            hidden={!gameRules.props.maxRoundsEnable}
            isInvalid={gameRules.props.maxRounds < 0}
          >
            <FormLabel htmlFor="maxRounds">Max Rounds</FormLabel>
            <NumberInput
              step={1}
              defaultValue={gameRules.props.maxRounds}
              min={0}
              onChange={(e) => updateRules({ maxRounds: parseInt(e) })}
            >
              <NumberInputField id="maxRounds" name="maxRounds" />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <FormHelperText>
              <Flex gap={1}>
                <Text>Max Rounds of the game, 0 is for</Text>
                <BiInfinite></BiInfinite>
              </Flex>
            </FormHelperText>
          </FormControl>
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2 }} gap="5">
          <FormControl>
            <FormLabel htmlFor="ticketIncrementEnable">
              Ticket increment
            </FormLabel>
            <Switch
              id="ticketIncrementEnable"
              name="ticketIncrementEnable"
              isChecked={gameRules.props.ticketIncrementEnable}
              size="lg"
              onChange={(e) => {
                updateRules({
                  ticketIncrementEnable: !gameRules.props.ticketIncrementEnable,
                });
              }}
            />
          </FormControl>
          <Flex direction="column" gap="5">
            <FormControl
              hidden={!gameRules.props.ticketIncrementEnable}
              isInvalid={gameRules.props.ticketPriceIncrement < 0.0001}
            >
              <FormLabel htmlFor="ticketPriceIncrement">
                Ticket increment value
              </FormLabel>
              <NumberInput
                step={1}
                defaultValue={gameRules.props.ticketPriceIncrement}
                min={0.0001}
                onChange={(e) => updateRules({ ticketPriceIncrement: e })}
              >
                <NumberInputField
                  id="ticketPriceIncrement"
                  name="ticketPriceIncrement"
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <FormHelperText>
                <Flex gap={1}>
                  <Text>Ticket increment (in %)</Text>
                </Flex>
              </FormHelperText>
            </FormControl>
            <FormControl
              hidden={!gameRules.props.ticketIncrementEnable}
              isInvalid={
                gameRules.props.ticketPriceIncrementStep <
                (houseRules ? houseRules.minPriceIncrementDuration : 0)
              }
            >
              <FormLabel htmlFor="ticketPriceIncrementStep">
                <Flex justifyContent="space-between" alignItems="center">
                  Ticket increment
                  <Flex gap="1" alignItems="center">
                    <span>~</span>
                    {toHHMMSS(
                      gameRules.props.ticketPriceIncrementStep * networkSpeed
                    )}
                    <EstimationInfo
                      networkSpeed={networkSpeed}
                    ></EstimationInfo>
                  </Flex>
                </Flex>
              </FormLabel>
              <NumberInput
                step={1}
                defaultValue={gameRules.props.ticketPriceIncrementStep}
                min={houseRules?.minPriceIncrementDuration}
                onChange={(e) =>
                  updateRules({ ticketPriceIncrementStep: parseInt(e) })
                }
              >
                <NumberInputField
                  id="ticketPriceIncrementStep"
                  name="ticketPriceIncrementStep"
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <FormHelperText>
                <Flex gap={1}>
                  <Text>
                    Ticket increment step (in block number) (min{" "}
                    {houseRules ? houseRules.minPriceIncrementDuration : ""})
                  </Text>
                </Flex>
              </FormHelperText>
            </FormControl>
          </Flex>
        </SimpleGrid>
      </Box>

      <Box hidden={activeStep !== 3}>
        {gameRules.props !== null && (
          <Flex gap={5} direction="column">
            <Box w="full">
              <Text as="b">Base rules</Text>
              <Table size="sm" title="Base rules">
                <Thead>
                  <Tr>
                    <Th>Rule</Th>
                    <Th>Value</Th>
                    <Th>
                      <Flex gap={1} alignItems="center">
                        <Text>Est.</Text>
                        <EstimationInfo
                          networkSpeed={networkSpeed}
                        ></EstimationInfo>
                      </Flex>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>Round base duration</Td>
                    <Td>#{gameRules.props.gameDuration}</Td>
                    <Td>
                      {toHHMMSS(gameRules.props.gameDuration * networkSpeed)}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Ticket duration step</Td>
                    <Td>#{gameRules.props.ticketStep}</Td>
                    <Td>
                      {" "}
                      {toHHMMSS(gameRules.props.ticketStep * networkSpeed)}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>Max step per block</Td>
                    <Td>#{gameRules.props.maxStepPerBlock}</Td>
                    <Td>
                      {" "}
                      {toHHMMSS(gameRules.props.ticketStep * networkSpeed)}
                    </Td>
                  </Tr>

                  <Tr>
                    <Td>Max rounds</Td>
                    <Td>
                      {gameRules.props.maxRounds > 0 ? (
                        gameRules.props.maxRounds
                      ) : (
                        <BiInfinite />
                      )}
                    </Td>
                    <Td></Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
            <Box>
              <Text as="b" mt={3}>
                Funds
              </Text>

              <Table size="sm">
                <Tbody>
                  <Tr>
                    <Td>Start Jackpot</Td>
                    <Td>
                      <Flex gap="1" alignContent="center" alignItems="center">
                        {formatMoney(gameRules.props.potBalance)}
                        <AvaxIcon />
                        <PriceConversion
                          value={gameRules.props.potBalance}
                        ></PriceConversion>
                      </Flex>
                    </Td>
                    <Td></Td>
                  </Tr>
                  <Tr>
                    <Td>Ticket price</Td>
                    <Td>
                      <Flex gap="1" alignContent="center" alignItems="center">
                        {formatMoney(gameRules.props.ticketPrice)}
                        <AvaxIcon />
                        <PriceConversion
                          value={gameRules.props.ticketPrice}
                        ></PriceConversion>
                      </Flex>
                    </Td>
                    <Td></Td>
                  </Tr>
                  {gameRules.props.ticketIncrementEnable && (
                    <Tr>
                      <Td></Td>
                      <Td>
                        {gameRules.props.ticketPriceIncrement +
                          "% every " +
                          gameRules.props.ticketPriceIncrementStep +
                          " blocks"}
                      </Td>
                      <Td></Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </Box>
            <Box>
              <Text as="b">Start game/round conditions</Text>
              <Table size="sm">
                <Tbody>
                  <Tr>
                    <Td>Game started after</Td>
                    <Td>
                      {!gameRules.props.startRoundWithTicket
                        ? "#" + gameRules.props.startRoundAfter + " blocks"
                        : "Game started with first ticket"}
                    </Td>
                  </Tr>

                  <Tr>
                    <Td>Round renewal</Td>
                    <Td>
                      {gameRules.props.renewRoundWithGameRules
                        ? "Played round will be renewed with game start rules"
                        : "Played round automatically restart at the round end"}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </Flex>
        )}
      </Box>
    </Flex>
  );
};
