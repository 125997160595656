import { Flex, Tag } from "@chakra-ui/react";
 import { useContext } from "react";
import { EtherContext } from "services/etherProvider";
import { GameContext } from "services/gameProvider";
import { PLAYED_KEY, useStorageGames } from "services/storageGamesProvider";
import { useAccount } from "wagmi";

export const GameTags = () => { 
  const played = useStorageGames(PLAYED_KEY)
  const { address, isConnected } = useAccount()
   const { game } = useContext(GameContext)
  const { etherHelper } = useContext(EtherContext)

  return (
    game && <Flex gap="1" wrap="wrap"

      justifyContent="left"
      w="full"
    >
      {address && game.creator.toLowerCase() ==
        address.toLowerCase() && (
        <Tag p="1" colorScheme="purple" variant="subtle">
          OWNER
        </Tag>
      )}
      {game.creator.toLowerCase() ==
        process.env.REACT_APP_HOUSE_ADDRESS?.toLowerCase() && (
        <Tag p="1" colorScheme="green" variant="subtle">
          HOUSE
        </Tag>
      )}
      {address &&
        game.currentRound.last[0]?.toLowerCase() == address.toLowerCase() &&
        game.currentRound.expired ? (
        <Tag p="1" colorScheme="green" variant="solid">
          WINNED
        </Tag>
      ) : (
          played.games.includes(game.gameId) && (
          <Tag p="1" colorScheme="blue" variant="subtle">
            PLAYED
          </Tag>
        )
      )}

    </Flex>


  );
};
