import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Box,
  Progress,
} from "@chakra-ui/react";
import { Fieldset } from "components/common/Fieldset/Fieldset";
import { Card } from "components/common/Card/Card";
import { Paginate } from "react-paginate-chakra-ui";
import { IGame } from "interfaces/IGame";
import styles_button from "../../common/Button/button.module.scss";
import { usePriceProvider, PriceFeedContext } from "services/priceProvder";
import { GameListElement } from "./GameListElement";
const tableColumnProps = {
  id: {},
  tags: {},
  jackpot: {},
  rounds: { display: { base: "none", md: "table-cell" } },
  timer: { display: { base: "none", md: "table-cell" }, minW: "180px" },
  price: { display: { base: "none", md: "table-cell" } },
  tickets: { display: { base: "none", lg: "table-cell" } },
  actions: { display: { base: "none", md: "table-cell" } },
};

export default function GamesList({
  games,
  totalGames,
  setPage,
  page,
  fieldset,
  maxH,
  boxShadow,
  border,
  loading,
  ...rest
}: {
  games: IGame[] | number[];

  totalGames?: number;
  setPage: (page: number) => void;
  page: number;
  fieldset?: boolean;
  maxH?: string;
  border?: string;
  boxShadow?: string;
  loading?: boolean;
}) {
  // Invoke when user click to request another page.

  const handlePageClick = (event: any) => {
    setPage(event);
    const newOffset = (event.selected * 20) % games.length;

    // (
    //     `User requested page number ${event.selected}, which is offset ${newOffset}`
    //   );
  };

  const GamesTable = () => (
    <Card
      bg="whiteAlpha.600"
      boxShadow={boxShadow ? boxShadow : "md"}
      {...rest}
      position="relative"
    >
      {loading && (
        <Box position="absolute" top="0" w="full" left="0">
          <Progress hasStripe isIndeterminate />
        </Box>
      )}

      <Paginate
        // required props 👇
        page={page}
        count={totalGames}
        pageSize={20}
        onPageChange={handlePageClick}
        margin={2}
        shadow="lg"
        fontWeight="blue"
        variant="outline"
        className={styles_button["lim-button"]}
        w="full"
      />

      <TableContainer
        maxH={maxH ? maxH : "full"}
        overflowY="scroll"
        position="relative"
      >
        <Table variant="simple" size={{ base: "sm", lg: "md" }}>
          <Thead>
            <Tr>
              <Th {...tableColumnProps.id}>ID</Th>
              <Th {...tableColumnProps.tags}>TAGS</Th>
              <Th {...tableColumnProps.jackpot}>JACKPOT</Th>
              <Th {...tableColumnProps.timer}>TIMER</Th>
              <Th {...tableColumnProps.price}>TICKET PRICE</Th>
              <Th {...tableColumnProps.tickets}>TICKETS</Th>
              <Th {...tableColumnProps.actions}>ACTIONS</Th>
            </Tr>
          </Thead>

          {!loading && (
            <Tbody>
              {games.map((r: any, idx: number) => {
                if (typeof r == "number") {
                  return (
                    r > 0 && (
                      <GameListElement
                        key={idx}
                        game={r}
                        tableColumnProps={tableColumnProps}
                      ></GameListElement>
                    )
                  );
                } else {
                  return (
                    r.gameId > 0 && (
                      <GameListElement
                        key={idx}
                        game={r}
                        tableColumnProps={tableColumnProps}
                      ></GameListElement>
                    )
                  );
                }
              })}
            </Tbody>
          )}
        </Table>
      </TableContainer>
    </Card>
  );

  if (fieldset) {
    return (
      <Fieldset title="Games">
        <GamesTable />
      </Fieldset>
    );
  } else {
    return <GamesTable />;
  }
}
