import { useState } from "react";
import { EtherHelper } from "services/etherProvider";
import { IGame } from "interfaces/IGame";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  NumberInput,
  FormControl,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/react";
import { Button } from "components/common/Button/Button";
import { AvaxIcon } from "components/common/CustomIcons/AvaxIcon";
import { useAddRecentTransaction } from "@rainbow-me/rainbowkit";
export function FundGameButton({
  etherHelper,
  refreshState,
  game,
  ...rest
}: {
  refreshState: () => void;
  etherHelper?: EtherHelper;
  game?: IGame;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const addRecentTransaction = useAddRecentTransaction();

  const fundGame = (event: any) => {
    event.preventDefault();
    setLoading(true);
    let data = event.target;
    etherHelper
      ?.send(
        "fundGame",
        {
          _gameId: game?.gameId,
          _gameRound: game?.currentRound.roundId,
        },
        EtherHelper.toWei(data.fund.value.toString())
      )
      .then((hash: any) => {
        addRecentTransaction({
          hash: hash,
          description:
            "Funded round #" +
            game?.currentRound.roundId +
            " for game #" +
            game?.gameId +
            " with " +
            data.fund.value.toString() +
            " currency",
        });
        refreshState();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <Button onClick={onOpen} colorScheme="yellow">
        Fund Game
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <form onSubmit={fundGame}>
          <ModalContent>
            <ModalHeader>Modal Title</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl mb="10">
                <FormLabel htmlFor="ticketPrice">
                  Fund the game in ( <AvaxIcon />)
                </FormLabel>
                <NumberInput step={0.05} defaultValue={0.05} min={0.05}>
                  <NumberInputField name="fund" id="fund-input" />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormHelperText>
                  Fund this game with additional reward (min. 0.05)
                </FormHelperText>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                disabled={loading}
                colorScheme="blue"
                mr={3}
                onClick={onClose}
              >
                Close
              </Button>
              <Button
                isLoading={loading}
                variant="ghost"
                type="submit"
                colorScheme="green"
              >
                Fund
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}
