import {
  Flex,
  Text,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Badge,
  ModalHeader,
} from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useState } from "react";
import { Button } from "components/common/Button/Button";
import { EtherContext } from "services/etherProvider";
import GamesList from "./GameList";
import { BiExit, BiExpand } from "react-icons/bi";
import { IGame } from "interfaces/IGame";
import { UserContext } from "services/storageGamesProvider";
import { useAccount } from "wagmi";

export const MyGamesList = () => {
  const [page, setPage] = useState(0);
  const [games, setGames] = useState<IGame[]>([]);
  const [myGamesLength, setMyGamesLength] = useState(0);
  const { address } = useAccount();
  const { etherHelper } = useContext(EtherContext);
  const { played, starred } = useContext(UserContext);
  const {
    isOpen: isOpenGameList,
    onOpen: onOpenGameList,
    onClose: onCloseGameList,
  } = useDisclosure();
  const [expand, setExpand] = useState(false);
  const loadMyGames = useCallback(() => {
    etherHelper?.call("getMyGamesIndexes", { _page: page }).then((a: any) => {
      console.log(a, page);
      if (a) {
        setGames(a);
      }
    });
  }, [etherHelper, page]);

  useEffect(() => {
    etherHelper?.call("getOwnedGamesLength").then((a: any) => {
      console.log(a);

      if (a) {
        setMyGamesLength(a);
      }
    });
  }, []);

  useEffect(() => {
    if (isOpenGameList) {
      loadMyGames();
    }
  }, [loadMyGames, isOpenGameList]);

  return (
    <>
      <Button w="full" mr="5" onClick={onOpenGameList}>
        Show my games
      </Button>
      <Modal
        isOpen={isOpenGameList}
        onClose={onOpenGameList}
        size={expand ? "full" : "6xl"}
        isCentered
      >
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>
            <Flex
              justifyContent="flex-end"
              p="3"
              gap="3"
              zIndex="999"
              position="absolute"
              right="0"
              top="0"
            >
              <Button
                onClick={() => {
                  setExpand(!expand);
                }}
              >
                <BiExpand />
              </Button>
              <Button onClick={onCloseGameList}>
                <BiExit></BiExit>
              </Button>
            </Flex>
          </ModalHeader>
          <ModalBody minH="600px" mt="4">
            <Tabs variant="enclosed">
              <TabList>
                <Tab>Created Games</Tab>
                <Tab>Starred Games</Tab>
                <Tab>Played Games</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Flex direction="column" gap="3">
                    <Flex
                      my="1"
                      alignItems="center"
                      gap="5"
                      justifyContent="space-between"
                    >
                      <Flex gap="1" alignItems="center">
                        <Text as="b">Address: </Text>
                        <Badge p="1" fontSize="1rem" variant="subtle">
                          {address}
                        </Badge>
                      </Flex>
                      <Flex alignItems="center" gap="2">
                        <Text>Owned Games:</Text>
                        <Badge p="1" fontSize="1rem" variant="subtle">
                          {myGamesLength}
                        </Badge>
                      </Flex>
                    </Flex>
                    <Box>
                      <GamesList
                        border="none"
                        boxShadow="none"
                        maxH="600px"
                        games={games}
                        page={page}
                        setPage={setPage}
                        totalGames={20}
                      ></GamesList>
                    </Box>
                  </Flex>
                </TabPanel>
                <TabPanel>
                  <Flex direction="column" gap="3">
                    <Flex gap="1" alignItems="center">
                      <Text as="b">Starred games n: </Text>
                      <Badge p="1" fontSize="1rem" variant="subtle">
                        {starred.games.length}
                      </Badge>
                    </Flex>

                    <Box>
                      <GamesList
                        totalGames={starred.games.length}
                        maxH="600px"
                        border="none"
                        boxShadow="none"
                        games={starred.games}
                        page={page}
                        setPage={setPage}
                      ></GamesList>
                    </Box>
                  </Flex>
                </TabPanel>
                <TabPanel>
                  <Flex direction="column" gap="3">
                    <Flex gap="1" alignItems="center">
                      <Text as="b">Played games n: </Text>
                      <Badge p="1" fontSize="1rem" variant="subtle">
                        {played.games.length}
                      </Badge>
                    </Flex>

                    <Box>
                      <GamesList
                        totalGames={played.games.length}
                        border="none"
                        boxShadow="none"
                        maxH="600px"
                        games={played.games}
                        page={page}
                        setPage={setPage}
                      ></GamesList>
                    </Box>
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
