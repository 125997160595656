import { Box} from "@chakra-ui/react";
import styles from "./fieldset.module.scss";

export function Fieldset({
  title,
  children,

  ...rest
}:any) {
  return (
    <Box mt={5} {...rest} className={styles["fieldset"]}>
      <Box className={styles["legend"]}>{title}</Box>
      <Box w="100%" p="2">
        {children}
      </Box>
    </Box>
  );
}
