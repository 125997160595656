import { Flex, Text, Box, Tooltip, useToast, Heading } from "@chakra-ui/react";
import { useContext } from "react";
import { Button } from "components/common/Button/Button";
import { EtherContext } from "services/etherProvider";
import { Card } from "components/common/Card/Card";
import Jazzicon from "react-jazzicon";

import { StorageHandler, UserContext } from "services/storageGamesProvider";
import { motion } from "framer-motion";
import { MyGamesList } from "./MyGamesList";
import { ImportBackup } from "./ImportBackup";
import { CreateNewGame } from "./CreateNewGame/CreateNewGame";
import { useAccount } from "wagmi";
import { BiDownload } from "react-icons/bi";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { NotConnectedWallet } from "components/common/NotConnectedWallet";

export default function UserCard({
  refreshStates,
  ...rest
}: {
  refreshStates: () => void;
}) {
  const { etherHelper } = useContext(EtherContext);
  // const { isOpen:isOpenGameList, onOpen:onOpenGameList, onClose:onCloseGameList } = useDisclosure()
  const { starred, played } = useContext(UserContext);
  const { address } = useAccount();
  // const [faucetLoading, setFaucetLoading] = useState(false);
  const { isConnected } = useAccount();

  const toast = useToast({
    size: "xl",
    position: "top-left",
    variant: "top-accent",
  });

  // const faucet = () => {
  //   setFaucetLoading(true);
  //   fetch("/api/faucet", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       // 'Content-Type': 'application/x-www-form-urlencoded',
  //     },
  //     body: JSON.stringify({ address: address }),
  //   })
  //     .then(() => {
  //       // setTimeout(() => etherHelper!.updateBalances(), 3000)
  //       toast({
  //         title: "Faucet completed",
  //         description: "Check your wallet",
  //         status: "info",
  //         duration: 9000,
  //         isClosable: true,
  //       });
  //     })
  //     .catch((err) => {
  //       toast({
  //         title: "error",
  //         description: err.message,
  //         status: "error",
  //         duration: 9000,
  //         isClosable: true,
  //       });
  //     })
  //     .finally(() => {
  //       setFaucetLoading(false);
  //     });
  // };
  return (
    <Card
      as={motion.div}
      initial={{ scale: 0.5 }}
      animate={{ scale: 1 }}
      transition="0.5s easeIn"
      p={4}
      minW="30%"
      type="shadowed"
    >
      {isConnected ? (
        <Flex direction="column" w="full" gap="5">
          <Flex gap="5" justifyContent="center" alignItems="center">
            <Box
              transition="0.5s easeIn"
              transitionDelay="1s"
              height="fit-content"
            >
              {address && (
                <Jazzicon
                  diameter={100}
                  seed={parseInt(address.slice(2, 10), 16)}
                />
              )}
            </Box>
            <Flex direction="column" w="full">
              <Text>Starred Game: {starred.games.length}</Text>
              <Text>Played Games: {played.games.length} </Text>
            </Flex>
          </Flex>

          {etherHelper && etherHelper && (
            <Flex direction="column" gap={2}>
              <Flex w="full" gap="2">
                {/* <Button
                        colorScheme="blue"
                        variant="outline"
                        onClick={faucet}
                        isLoading={faucetLoading}
                      >
                        FAUCET
                      </Button> */}
                <Tooltip
                  hasArrow
                  placement="top"
                  label="Backup user information saved on this pc"
                >
                  <Button
                    colorScheme="blue"
                    variant="outline"
                    onClick={() => {
                      StorageHandler.backupStorage({ played, starred });
                    }}
                  >
                    <BiDownload />
                  </Button>
                </Tooltip>
                <ImportBackup played={played} starred={starred}></ImportBackup>
              </Flex>
              <CreateNewGame
                refreshStates={refreshStates}
                etherHelper={etherHelper}
              />
              <MyGamesList></MyGamesList>
            </Flex>
          )}
        </Flex>
      ) : (
        <NotConnectedWallet></NotConnectedWallet>
      )}
    </Card>
  );
}
