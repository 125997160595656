import { Flex, Heading, Tag, VStack } from "@chakra-ui/react";
import { GameStatusIcon } from "./GameStatusIcon";
import { GameStatusText } from "./GameStatusText";
import { GameTags } from "./GameTags";
import { GameContext } from "services/gameProvider";
import { useContext } from "react";

export const GameStatus = () => { 
  const { game } = useContext(GameContext)
  return (
    <Flex justifyContent="space-around">
      <VStack gap={3}>
        <Tag>
          Creator:
          {game?.creator.slice(0, 6) + "..." + game?.creator.slice(-4)}
        </Tag>
        <Heading size="xl"> GAME #{game?.gameId}</Heading>

        <Flex
          w="full"
          justifyContent="center"
          gap="3"
          alignContent="center"
          alignItems="center"
        >
          <GameStatusIcon fontSize="1.5rem"></GameStatusIcon>
          <GameStatusText />
        </Flex>
        <GameTags />
      </VStack>
    </Flex>
  );
};
