/*global windows.ethereum*/
// import { WalletState } from "@web3-onboard/core";
import { environments } from "environment/environment";

import { Text, Flex, createStandaloneToast, Link } from "@chakra-ui/react";
import { createContext, useEffect, useState } from "react";
// import { init, useAccountCenter } from "@web3-onboard/react";
import { BsEyeFill } from "react-icons/bs";
import { PublicClient, useAccount, useNetwork } from "wagmi";
import {} from "wagmi/connectors/injected";
import { readContract, writeContract, waitForTransaction } from "@wagmi/core";
import { usePublicClient } from "wagmi";
import { Block, BlockTag, Chain, custom, formatEther, parseEther } from "viem";
import {} from "wagmi";
import { localhost } from "viem/chains";
import { supportedChains } from "./wallets";

type ExecuteFunctionParams = Record<string, any>;

const MAX_PREVIOUS_BLOCKS = 50;
const DEFAULT_AVERAGE_NETWORK_SPEED = 2.5;

interface ExecuteFunctionOptions {
  contractAddress: string;
  abi: object;
  functionName: string;
  msgValue?: any;
  params?: ExecuteFunctionParams;
}

const basePayload: any = {
  address: process.env.REACT_APP_CONTRACT_ADDRESS as string,
  abi: environments.contracts.LastIsMeService.abi,
  functionName: "",
};

// export const CurrentBlockContext= createContext<{currentBlock:Block|null}>({currentBlock:null})
// export const useCurrentBlockProvider=()=>{
//   const provider = usePublicClient()
//   const { data, isError, isLoading } = useBlockNumber({watch:true} {watch:true})
//   const [block,setBlock]=useState<Block|null>(null)
//   console.log("block load")
//   useEffect(()=>{
//     if(!isLoading && data!= block?.number){
//       provider.getBlock({blockNumber:data}).then(cb=>{setBlock(cb)})
//     }
//   },[data])
//   return   block
// }

export const EtherContext = createContext<{
  etherHelper?: EtherHelper;
  chainSelected: Chain | any;
  setChainSelected?: (c: Chain | any) => void;
}>({ chainSelected: supportedChains[0] });

const { toast } = createStandaloneToast({
  defaultOptions: {
    size: "xl",
    position: "top-left",
    variant: "top-accent",
  },
});
export const useProvider = () => {
  const { address, isConnected } = useAccount();
  const provider = usePublicClient();

  const { chain } = useNetwork();
  const [chainSelected, setChainSelected] = useState<Chain | any>(
    supportedChains[0]
  );
  const [etherHelper, setEtherHelper] = useState<EtherHelper>();

  // useEffect(()=>{
  //   if(isConnected){
  //     console.log(currentBlock)
  //     etherHelper?.getBlock(currentBlock).then((cb)=>{
  //       etherHelper?.getNetworkSpeed(cb)

  //     })
  //   }
  // },[currentBlock])
  useEffect(() => {
    basePayload.account = address;
  }, [address]);
  useEffect(() => {
    if (chain) {
      setChainSelected(chain);
    }
  }, [isConnected, chain]);
  useEffect(() => {
    let etherH = new EtherHelper(provider, toast, chainSelected as Chain);
    setEtherHelper(etherH);
  }, [chainSelected]);

  return { etherHelper, chainSelected, setChainSelected };
};
export class EtherHelper {
  // private provider: ethers.providers.Web3Provider;
  private previousBlocks: any = {};
  private toast: any;
  private provider: PublicClient;
  private lastBlock: Block | null = null;
  private varNetworkSpeed = DEFAULT_AVERAGE_NETWORK_SPEED;
  private refreshLock: boolean = false;
  private timeDrift: number = 0;
  private guid: string;
  private chain: Chain;

  constructor(provider: PublicClient, toast: any, chain: Chain) {
    this.toast = toast;
    this.provider = provider;
    this.fetchPreviusBlocks();
    this.guid = (Math.random() * 100000).toFixed(0);
    this.chain = chain;
  }
  private async fetchPreviusBlocks() {
    let blocks: any = {};
    let lastBlock = await this.getBlock("latest");
    if (lastBlock != null && lastBlock.number != null) {
      for (let i = MAX_PREVIOUS_BLOCKS / 2; i > 0; i--) {
        let block = await this.getBlock(lastBlock.number - BigInt(i));
        if (block.number) {
          blocks[block.number.toString()] = {
            number: block.number,
            timestamp: block.timestamp,
          };
        }
      }
      this.previousBlocks = blocks;
    }
  }

  public async getBlock(data: bigint | BlockTag = "latest") {
    if (typeof data == "bigint") {
      return await (this.provider as any).getBlock({
        blockNumber: data as bigint,
      });
    } else {
      return await (this.provider as any).getBlock({
        blockTag: data as BlockTag,
      });
    }
  }
  public getLastBlockNumber() {
    if (this.lastBlock != null) {
      return this.lastBlock.number;
    } else {
      return 0;
    }
  }
  public async getNetworkSpeed(lastBlock: bigint) {
    if (this.refreshLock === false) {
      this.refreshLock = true;
      if (
        !this.lastBlock ||
        this.lastBlock == null ||
        this.lastBlock.number! < lastBlock
      ) {
        this.lastBlock = await this.getBlock("latest");
      }
      let blocks: any = Object.values(this.previousBlocks);

      if (
        this.lastBlock != null &&
        blocks[blocks.length - 1] &&
        blocks[blocks.length - 1].number != this.lastBlock.number
      ) {
        let missingBlocksNumber =
          this.lastBlock.number! - blocks[blocks.length - 1].number;

        if (Number(missingBlocksNumber) - 1 > 0) {
          for (let i = Number(missingBlocksNumber); i > 0; i--) {
            if (blocks.length >= MAX_PREVIOUS_BLOCKS) {
              let b = blocks.shift();
              delete this.previousBlocks[b.number.toString()];
            }
            let block: Block = await this.getBlock(
              this.lastBlock.number! - BigInt(i)
            );
            if (i === 1) {
              this.lastBlock = block;
            }
            blocks.push({ number: block.number, timestamp: block.timestamp });
            this.previousBlocks[block.number!.toString()] = {
              number: block.number,
              timestamp: block.timestamp,
            };
          }
          if (blocks.length >= MAX_PREVIOUS_BLOCKS) {
            let b = blocks.shift();
            delete this.previousBlocks[b.number.toString()];
          }
          if (blocks[blocks.length - 1].number !== this.lastBlock.number)
            blocks.push({
              number: this.lastBlock.number,
              timestamp: this.lastBlock.timestamp,
            });

          this.previousBlocks[this.lastBlock.number!.toString()] = {
            number: this.lastBlock.number,
            timestamp: this.lastBlock.timestamp,
            realTimestamp: Math.floor(Date.now() / 1000),
          };
        }

        let sum = 0;
        let driftSum = 0;
        let driftCont = 0;
        for (let i = 0; i < blocks.length - 1; i++) {
          sum += Number(blocks[i + 1].timestamp) - Number(blocks[i].timestamp);
          if (blocks[i].realTimestamp) {
            driftSum +=
              Number(blocks[i].realTimestamp) - Number(blocks[i].timestamp);
            driftCont++;
          }
        }
        if (blocks.length - 1 > 0) {
          this.varNetworkSpeed = sum / (blocks.length - 1);
        }
        if (driftCont > 0) {
          this.timeDrift = driftSum / driftCont;
        }
      }

      this.refreshLock = false;
    }

    return {
      speed: this.varNetworkSpeed,
      lastBlock: this.lastBlock,
      timeDrift: this.timeDrift,
    };
  }

  public static toWei(number: string): bigint {
    return parseEther(number);
  }

  public static fromWei(number: bigint): string {
    return formatEther(number);
  }

  /**
   * default method for using read function form contract
   * @param functionName
   * @param params
   * @returns value from contract
   */
  async call(
    functionName: string,
    params?: ExecuteFunctionParams | null,
    customPayload?: any
  ) {
    let payload = { ...basePayload };
    if (customPayload) {
      payload = { ...payload, ...customPayload };
    }
    payload.functionName = functionName;
    if (params != undefined && params != null) {
      payload.args = [];
      for (let val of Object.values(params)) {
        if (typeof val == "number") {
          payload.args.push(BigInt(val));
        } else {
          payload.args.push(val);
        }
      }
    }
    const res: any = await readContract(payload);

    return res;
  }

  /**
   * default method for using write function in contract
   * @param functionName
   * @param params
   * @param msgValue ~ string value of the send function
   * @returns tx
   */
  async send(
    functionName: string,
    params?: ExecuteFunctionParams,
    msgValue?: string | bigint
  ) {
    return new Promise<`0x${string}`>((resolve, rej) => {
      const payload = { ...basePayload };
      payload.functionName = functionName;
      if (params != undefined) {
        payload.args = Object.values(params);
      }
      if (msgValue && msgValue !== "0" && typeof msgValue === "string") {
        payload.value = EtherHelper.toWei(msgValue);
      } else if (msgValue) {
        payload.value = msgValue;
      }
      let idToast = this.toast({
        title: "Transaction requested",
        description: "Sign transaction in your wallet",
        variant: "top-accent",
        status: "loading",
        isClosable: false,
        duration: null,
      });
      writeContract(payload)
        .then(async (res) => {
          if (res != null) {
            this.toast.close(idToast);

            idToast = this.toast({
              title: "Transaction sent",
              description: "Waiting for confirmations from the blockchain",
              variant: "top-accent",
              status: "loading",
              isClosable: false,
              duration: null,
            });
            const receipt = await waitForTransaction({
              hash: res.hash,
              confirmations: 2,
            });

            this.toast.close(idToast);
            this.toast({
              title: "Transaction complete",
              description: (
                <Link
                  href={
                    this.chain.blockExplorers
                      ? (this.chain.blockExplorers.default.url as string) +
                        "/tx/" +
                        res.hash
                      : "#"
                  }
                >
                  <Flex
                    w="full"
                    alignContent="center"
                    alignItems="center"
                    gap="2"
                  >
                    <BsEyeFill></BsEyeFill>
                    <Text>View in explorer</Text>
                  </Flex>
                </Link>
              ),
              duration: 9000,
              variant: "top-accent",
              status: "success",

              isClosable: true,
            });

            resolve(res.hash);
          }
        })
        .catch((err) => {
          // console.log(JSON.stringify(err))
          this.toast.close(idToast);
          this.toast({
            title: "Transaction error",
            variant: "top-accent",
            description: err.details,
            status: "error",
            isClosable: false,
            duration: 9000,
          });
          rej(err);
          console.log(err);
        })
        .finally(() => {});
    });
  }
}
