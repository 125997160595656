import { Metadata } from "nostr-react";
import { nip19 } from "nostr-tools";
import { createContext, useState } from "react";

export const NostrUserContext = createContext<
  NostrUser & {
    update: (a: any) => void;
    logout: () => void;
    decodepubkey: (pubk: string) => string;
  }
>({ update: () => {}, logout: () => {}, decodepubkey: nip19.npubEncode });
interface NostrUser {
  seckey?: string;
  pubkey?: string;
}
export function useNostrUser() {
  const [state, setState] = useState<NostrUser>();

  function update(a: NostrUser) {
    setState({ ...state, ...a });
  }
  function logout() {
    setState({});
  }

  return {
    ...state,
    update,
    logout,
    decodepubkey: nip19.npubEncode,
  };
}
