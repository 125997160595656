import { ButtonProps, Tooltip, Text, Flex } from "@chakra-ui/react";
import { useAddRecentTransaction } from "@rainbow-me/rainbowkit";
import { Button } from "components/common/Button/Button";
import { motion } from "framer-motion";
import { IGame } from "interfaces/IGame";
import { useContext } from "react";
import { BiWindowClose } from "react-icons/bi";
import { EtherContext } from "services/etherProvider";
import { UserContext } from "services/storageGamesProvider";
import Typewriter from "typewriter-effect";

export const EndButton = ({
  gameData,
  reloadGame,
  large,
  ...rest
}: ButtonProps & {
  gameData: IGame;
  reloadGame: () => void;
  large?: boolean;
}) => {
  const { etherHelper } = useContext(EtherContext);
  const addRecentTransaction = useAddRecentTransaction();

  const endGameRound = () => {
    etherHelper
      ?.send("endGameRound", {
        _gameId: gameData?.gameId,
        _gameRound: gameData?.currentRound.roundId,
      })
      .then((hash: any) => {
        addRecentTransaction({
          hash: hash,
          description:
            "Endend round #" +
            gameData?.currentRound.roundId +
            " for game #" +
            gameData.gameId,
        });
        reloadGame();
      });
  };

  if (large) {
    return (
      <Button
        as={motion.div}
        h="70px"
        w="200px"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        whileHover={{ scale: [1.1, 1.2] }}
        transition={{
          duration: "0.8",
          delay: "0.5",
          ease: "0, 0.71, 0.2, 1.01",
        }}
        colorScheme="yellow"
        onClick={() => {
          endGameRound();
        }}
        {...rest}
      >
        <Flex
          justifyContent="center"
          gap="3"
          alignItems="center"
          direction="column"
        >
          <Text wordBreak="break-all">
            <Typewriter
              onInit={(typewriter) => {
                typewriter
                  .typeString("End current round")
                  .callFunction(() => {
                    typewriter.stop();
                  })

                  .start();
              }}
              options={{ autoStart: true, loop: false }}
            ></Typewriter>
          </Text>
          <BiWindowClose size="20" />
        </Flex>
      </Button>
    );
  } else {
    return (
      <Tooltip label="End current round" placement="top" hasArrow>
        <Button
          whileHover={{ scale: [1.05, 1.2] }}
          transition={{
            duration: "1.8",
            delay: "1.5",
            ease: "0, 0.71, 0.2, 1.01",
          }}
          as={motion.div}
          colorScheme="yellow"
          onClick={() => {
            endGameRound();
          }}
        >
          <BiWindowClose />
        </Button>
      </Tooltip>
    );
  }
};
