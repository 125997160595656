// Importa le dipendenze necessarie
import { useContext, useMemo } from "react";

import { Box, Text, Flex, Badge, Spacer, Img } from "@chakra-ui/react";
import { useProfile } from "nostr-react";
import { Message } from ".";
import { NostrUserContext } from "./NostrUserContext";
export function MessageElement({ message, ...rest }: { message: Message }) {
  const { pubkey, decodepubkey } = useContext(NostrUserContext);
  const { data: userData } = useProfile({
    pubkey: message.pubkey,
  });

  const dateString = useMemo(() => {
    let date = new Date(message.created * 1000);
    return date.toLocaleTimeString();
  }, [message.created]);

  const npub = useMemo(() => {
    return decodepubkey(message.pubkey);
  }, [message.pubkey]);

  const isOwned = useMemo(() => {
    return message.pubkey == pubkey;
  }, [message]);

  return (
    <Box {...rest} bgColor={isOwned ? "blue.100" : "right"}>
      <Flex direction="column">
        <hr />
        <Flex gap="1" justifyItems={"center"}>
          <Flex gap="1">
            {userData && (
              <Img
                src={userData.picture}
                borderRadius={999}
                height="20px"
              ></Img>
            )}
            <Flex direction={"column"}>
              <Badge colorScheme={isOwned ? "green" : "gray"}>
                {userData
                  ? userData.name
                  : npub.substring(0, 8) +
                    ".." +
                    npub.substring(npub.length - 4)}
              </Badge>
              {message.sender && (
                <Badge colorScheme="purple">
                  {message.sender.substring(0, 4) +
                    ".." +
                    message.sender.substring(message.sender.length - 4)}
                </Badge>
              )}
            </Flex>
          </Flex>

          <Text
            px={2}
            textAlign={isOwned ? "right" : "left"}
            wordBreak="break-all"
          >
            {message.message}
          </Text>

          <Spacer></Spacer>
          <Flex direction="column-reverse">
            <Text fontSize="xs" color="gray">
              {dateString}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}
