import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Divider,
  useSteps,
  Box,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { Button } from "components/common/Button/Button";
import { EtherHelper } from "services/etherProvider";
import { BiExit } from "react-icons/bi";
import { useBlockNumber } from "wagmi";
import { AnimatePresence, motion } from "framer-motion";
import { CreateNewGameFormStepper } from "./CreateNewGameStepper";
import { CreateNewGameForm } from "./CreateNewGameForm";
import { HouseRulesContext } from "services/houserulesProvider";

interface newGameProps {
  ticketPrice: number;
  ticketPriceIncrement: number;
  ticketPriceIncrementStep: number;
  gameDuration: number;
  ticketStep: number;
  maxStepPerBlock: number;
  maxRounds: number;
  startRoundAfter: number;
  startRoundWithTicket: boolean;
}
const steps = [
  { title: "Base", description: "rules" },
  { title: "Custom", description: "rules" },
  { title: "Confirm", description: "summary" },
];
export const CreateNewGame = ({
  refreshStates,
  etherHelper,
  ...rest
}: {
  refreshStates: () => void;
  etherHelper: EtherHelper;
}) => {
  const {
    isOpen: isOpenGameCreate,
    onOpen: onOpenGameCreate,
    onClose: onCloseGameCreate,
  } = useDisclosure();
  const { houseRules } = useContext(HouseRulesContext);
  const [gameRules, setGameRules] = useState({
    props: {
      ticketPrice: houseRules ? houseRules.minTicketPrice.toString() : "0.005",
      ticketPriceIncrement: 0,
      ticketPriceIncrementStep: houseRules
        ? houseRules.minPriceIncrementDuration
        : 10,
      gameDuration: houseRules ? houseRules.minDuration : 30,
      ticketStep: 10,
      maxStepPerBlock: 10,
      maxRounds: 0,
      potBalance: houseRules
        ? houseRules.minPotBalanceForNewGames.toString()
        : "0.05",
      startRoundAfter: 0,
      ticketIncrementEnable: false,
      startRoundWithTicket: true,
      renewRoundWithGameRules: false,
    },
  });

  const [direction, setDirection] = useState(1);
  const { activeStep, goToNext, goToPrevious, setActiveStep } = useSteps({
    index: 1,
    count: steps.length,
  });
  const nextStep = () => {
    setDirection(1);
    goToNext();
  };

  const prevStep = () => {
    if (activeStep < 3) {
      setDirection(1);
    } else {
      setDirection(-1);
    }
    if (activeStep > 1) {
      goToPrevious();
    }
  };
  const [loadingNewGame, setLoadingNewGame] = useState(false);
  const [networkSpeed, setNetworkSpeed] = useState(0);

  const { data: currentBlock } = useBlockNumber({ watch: true });

  useEffect(() => {
    if (currentBlock) {
      etherHelper.getNetworkSpeed(currentBlock).then((e) => {
        setNetworkSpeed(e.speed);
      });
    }
  }, [etherHelper, currentBlock]);

  const onCreateGameClickConnect = (event: any) => {
    event.preventDefault();
    setLoadingNewGame(true);

    let ticketPrice = EtherHelper.toWei(gameRules.props.ticketPrice);

    let ticketPriceIncrement = EtherHelper.toWei(
      (+gameRules.props.ticketPriceIncrement / 100).toString()
    );
    let ticketPriceIncrementStep = gameRules.props.ticketPriceIncrementStep;
    if (!gameRules.props.ticketIncrementEnable) {
      ticketPriceIncrementStep = 0;
      ticketPriceIncrement = EtherHelper.toWei("0");
    }

    etherHelper
      ?.send(
        "newGame",
        {
          props: {
            ticketPrice: ticketPrice,
            ticketPriceIncrement: ticketPriceIncrement,
            ticketPriceIncrementStep: ticketPriceIncrementStep,
            gameDuration: gameRules.props.gameDuration,
            ticketStep: gameRules.props.ticketStep,
            maxStepPerBlock: gameRules.props.maxStepPerBlock,
            maxRounds: gameRules.props.maxRounds,
            startRoundAfter: gameRules.props.startRoundAfter,

            startRoundWithTicket: gameRules.props.startRoundWithTicket,
            renewRoundWithGameRules: gameRules.props.renewRoundWithGameRules,
          },
        },
        gameRules.props.potBalance
      )
      .then(
        (data: any) => {
          onCloseGameCreate();
          refreshStates();
          setLoadingNewGame(false);
        },
        (error: any) => {
          onCloseGameCreate();
          setLoadingNewGame(false);
        }
      );
  };

  return (
    <>
      <Button
        w="full"
        onClick={() => {
          setActiveStep(1);
          onOpenGameCreate();
        }}
        mr="5"
      >
        Create new game
      </Button>
      <Modal
        isOpen={isOpenGameCreate}
        onClose={onCloseGameCreate}
        size={{ base: "full", md: "4xl" }}
        isCentered
      >
        <ModalOverlay />

        <ModalContent h={{ base: "50px", md: "2xl" }} overflowY="scroll">
          <Flex
            justifyContent="flex-end"
            p="3"
            gap="3"
            position="absolute"
            right="0"
          >
            <Button variant="ghost" onClick={onCloseGameCreate}>
              <BiExit></BiExit>
            </Button>
          </Flex>
          <form onSubmit={onCreateGameClickConnect}>
            <Flex direction="column" h="full" justifyContent="space-between">
              <ModalHeader>Create New Game</ModalHeader>
              <ModalBody>
                <CreateNewGameFormStepper
                  steps={steps}
                  activeStep={activeStep}
                />
                <Divider my={3} />
                <AnimatePresence initial={false} custom={activeStep}>
                  <Box
                    as={motion.div}
                    key={activeStep}
                    custom={direction}
                    initial={{
                      opacity: 0,
                      originX: direction === 1 ? 0 : 1,
                    }}
                    animate={{ opacity: 1, originX: 0.5 }}
                    exit={{
                      opacity: 0,
                      position: "absolute",
                      originX: direction === 1 ? 0 : 1,
                    }}
                    transition={{ duration: "0.5" }}
                  >
                    <CreateNewGameForm
                      activeStep={activeStep}
                      networkSpeed={networkSpeed}
                      gameRules={gameRules}
                      setGameRules={setGameRules}
                    ></CreateNewGameForm>
                  </Box>
                </AnimatePresence>
              </ModalBody>
              <ModalFooter>
                <Flex gap={2}>
                  <Button
                    isDisabled={activeStep <= 1 || loadingNewGame}
                    onClick={prevStep}
                  >
                    Previous
                  </Button>
                  <Button isDisabled={activeStep === 3} onClick={nextStep}>
                    Next
                  </Button>
                  <Button
                    isDisabled={activeStep !== 3}
                    type="submit"
                    colorScheme="blue"
                    isLoading={loadingNewGame}
                  >
                    Create
                  </Button>
                </Flex>
              </ModalFooter>
            </Flex>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};
