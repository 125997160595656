import { Box } from "@chakra-ui/layout";
import {
  Text,
  Flex,
  Stack,
  NumberInput,
  NumberInputField,
  Spacer,
} from "@chakra-ui/react";
import {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
  ReactElement,
} from "react";
import UserCard from "components/home/UserCard";
import GamesList from "components/home/GameList";
import { Card } from "components/common/Card/Card";
import { Radio, RadioGroup } from "@chakra-ui/react";
import { FaSearch } from "react-icons/fa";
import { GrClose, GrUpdate } from "react-icons/gr";
import { Button } from "components/common/Button/Button";
import { EtherContext } from "services/etherProvider";
import { motion } from "framer-motion";
import { Loading } from "components/common/Loading/Loading";
import Marquee from "react-fast-marquee";
import { Layout } from "components/layout";
import { IGame } from "interfaces/IGame";
import {
  PLAYED_KEY,
  STARRED_KEY,
  UserContext,
  useStorageGames,
} from "services/storageGamesProvider";
import {
  HouseRulesContext,
  useHouseRulesProvider,
} from "services/houserulesProvider";
import { HouseRulesCard } from "components/home/HouseRulesCard";
import { PriceFeedContext, usePriceProvider } from "services/priceProvder";
type Repo = {
  name: string;
  stargazers_count: number;
};

const Dapp = () => {
  const { etherHelper, chainSelected } = useContext(EtherContext);
  const { priceFeed } = usePriceProvider();

  const { houseRules, reloadHouseRules } = useHouseRulesProvider(etherHelper);
  const [searchId, setSearchId] = useState<number | string | null>(null);
  const searchRef = useRef(null);
  const [gamesListInfo, setGamesListInfo] = useState({
    gamesAllLength: 0,
    gamesActiveLength: 0,
    gamesEndedLength: 0,
    accountCount: 0,
  });
  const [gamesLoading, setGamesLoading] = useState(false);
  const starred = useStorageGames(STARRED_KEY);
  const played = useStorageGames(PLAYED_KEY);
  const [gamesLength, setGamesLength] = useState(0);
  const [gamesList, setGamesList] = useState<IGame[] | number[]>([]);
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState<"all" | "active" | "ended" | string>(
    "active"
  );

  const loadNewGameList = useCallback(
    (newGameList?: IGame[] | number[]) => {
      if (newGameList) {
        setGamesList(newGameList);
      }
      setGamesLoading(false);
    },
    [setGamesList]
  );
  const loadAllGames = useCallback(
    (page: number) => {
      etherHelper?.call("getAllGames", { _page: page }).then((a: any) => {
        loadNewGameList(a);
      });
    },
    [etherHelper, loadNewGameList]
  );

  const loadActiveGames = useCallback(
    (page: number) => {
      etherHelper?.call("getActiveGames", { _page: page }).then((a: any) => {
        loadNewGameList(a);
      });
    },
    [etherHelper, loadNewGameList]
  );

  const loadEndedGames = useCallback(
    (page: number) => {
      etherHelper
        ?.call("getEndedGames", { _page: page })
        .then((a: any) => loadNewGameList(a));
    },
    [etherHelper, loadNewGameList]
  );

  const getAllGamesLength = useCallback(async () => {}, [etherHelper]);
  const loadGame = useCallback(() => {
    if (searchId != null) {
      let searchParam = searchId;
      if (typeof searchParam == "string") {
        searchParam = parseInt(searchParam);
      }
      loadNewGameList([searchParam]);
    }
  }, [loadNewGameList, searchId]);

  const refreshStates = useCallback(
    async (page = 0) => {
      const tmpGamesInfos = {
        gamesAllLength: 0,
        gamesActiveLength: 0,
        gamesEndedLength: 0,
        accountCount: 0,
      };
      if (etherHelper) {
        tmpGamesInfos.gamesAllLength = (
          await etherHelper?.call("getAllGamesLength")
        ).toString();
        tmpGamesInfos.gamesActiveLength = parseInt(
          await etherHelper?.call("getActiveGamesLength")
        );

        tmpGamesInfos.gamesEndedLength = parseInt(
          await etherHelper?.call("getEndedGamesLength")
        );
        tmpGamesInfos.accountCount = parseInt(
          await etherHelper?.call("getAccountCount")
        );
      }

      setGamesListInfo(tmpGamesInfos);
      if (searchId == null) {
        switch (filter) {
          case "active":
            loadActiveGames(page);
            setGamesLength(tmpGamesInfos.gamesActiveLength);

            break;
          case "ended":
            loadEndedGames(page);
            setGamesLength(tmpGamesInfos.gamesEndedLength);

            break;

          default:
            loadAllGames(page);
            setGamesLength(tmpGamesInfos.gamesAllLength);

            break;
        }
      } else {
        loadGame();
      }
    },
    [searchId, filter, loadActiveGames, loadEndedGames, loadAllGames, loadGame]
  );

  useEffect(() => {
    //client side code

    setGamesLoading(true);

    refreshStates(page);
  }, [refreshStates, page]);

  const reloadTable = () => {
    setGamesLoading(true);

    refreshStates(page);
  };

  return (
    <>
      <UserContext.Provider value={{ starred, played }}>
        <Flex direction="column" gap="5">
          <Flex direction={{ base: "column", md: "row" }} gap="5">
            <PriceFeedContext.Provider value={{ priceFeed }}>
              <HouseRulesContext.Provider
                value={{ houseRules, reloadHouseRules }}
              >
                <HouseRulesCard chain={chainSelected}></HouseRulesCard>
                <UserCard refreshStates={refreshStates}></UserCard>
              </HouseRulesContext.Provider>
            </PriceFeedContext.Provider>
          </Flex>
          <Card cursor="pointer" position="relative">
            <Marquee
              speed={50}
              autoFill={true}
              pauseOnClick
              gradient
              gradientWidth="200"
            >
              <Text mx="8">
                Total Games: <b>{gamesListInfo.gamesAllLength}</b>
              </Text>
              <Text mx="8">
                Active Games: <b>{gamesListInfo.gamesActiveLength}</b>
              </Text>
              <Text mx="8">
                Ended Games: <b>{gamesListInfo.gamesEndedLength}</b>
              </Text>
              <Text mx="8">
                Total Players: <b>{gamesListInfo.accountCount}</b>
              </Text>
            </Marquee>
          </Card>

          <Card w="full" position="relative">
            <Flex
              gap={5}
              direction={{ base: "column", md: "row" }}
              justifyContent="space-between"
              alignContent="center"
              alignItems="center"
            >
              <Box as={Flex} gap={1}>
                <Button
                  onClick={() => {
                    setSearchId(
                      searchRef.current == null
                        ? null
                        : (searchRef.current as any).value
                    );
                  }}
                >
                  <FaSearch />
                </Button>
                <Button
                  onClick={() => {
                    setSearchId(null);
                  }}
                >
                  <GrClose />
                </Button>
                <NumberInput
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setSearchId(
                        searchRef.current == null
                          ? null
                          : (searchRef.current as any).value
                      );
                    }
                  }}
                  min={0}
                  max={gamesListInfo.gamesAllLength - 1}
                  placeholder="3"
                >
                  <NumberInputField ref={searchRef} />
                </NumberInput>
              </Box>
              <Spacer />
              <Box as={Flex} gap="2" justifyContent="right" alignItems="center">
                <RadioGroup
                  onChange={(e) => {
                    setPage(0);
                    setFilter(e);
                    setSearchId(null);
                  }}
                  value={filter}
                >
                  <Stack direction="row">
                    <Radio value="all">All</Radio>
                    <Radio value="active">Actives</Radio>
                    <Radio value="ended">Ended</Radio>
                  </Stack>
                </RadioGroup>
                <Button
                  onClick={() => {
                    reloadTable();
                  }}
                >
                  <GrUpdate />
                </Button>
              </Box>
            </Flex>
          </Card>
          {(gamesList.length < 1 || gamesLength === 0) && <Loading></Loading>}
          <Box
            hidden={!etherHelper}
            as={motion.div}
            initial={{ scaleY: 0 }}
            animate={gamesList.length > 0 ? { scaleY: 1 } : {}}
            transition="2s easeIn"
          >
            <PriceFeedContext.Provider value={{ priceFeed }}>
              <GamesList
                loading={gamesLoading}
                totalGames={gamesLength}
                fieldset={true}
                games={gamesList}
                page={page}
                setPage={setPage}
              ></GamesList>
            </PriceFeedContext.Provider>
          </Box>
        </Flex>
      </UserContext.Provider>

      {/* <AccountServiceState ></AccountServiceState> */}
    </>
  );
};
Dapp.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>;
};
export default Dapp;
