import {
  Button as ChakraButton,
  ButtonProps,
  ChakraProps,
  forwardRef,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import styles from "./button.module.scss";
export const Button = forwardRef(
  (
    {
      onClick,
      children,
      ...rest
    }: JSX.IntrinsicAttributes &
      ButtonProps &
      ChakraProps & { onClick?: any; children: ReactNode },
    ref
  ) => (
    <ChakraButton
      ref={ref}
      {...rest}
      onClick={onClick}
      borderColor="black"
      className={styles["lim-button"]}
    >
      {children}
    </ChakraButton>
  )
);
