import { ConnectButton as ConnectButtonRainbow } from "@rainbow-me/rainbowkit";
import { Button } from "./Button/Button";
import { Box } from "@chakra-ui/react";

export const ConnectButton = () => {
  return (
    <ConnectButtonRainbow.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");
        const chainButton = (chain: any) => (
          <Box
            cursor="pointer"
            onClick={openChainModal}
            style={{ display: "flex", alignItems: "center" }}
          >
            {chain.hasIcon && (
              <div
                style={{
                  background: chain.iconBackground,

                  borderRadius: 999,
                  overflow: "hidden",
                  marginRight: 4,
                }}
              >
                {chain.iconUrl && (
                  <img alt={chain.name ?? "Chain icon"} src={chain.iconUrl} />
                )}
              </div>
            )}
          </Box>
        );
        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <>
                    <Button colorScheme="blue" onClick={openConnectModal}>
                      Connect Wallet
                    </Button>
                  </>
                );
              }

              if (chain.unsupported) {
                return (
                  <Button colorScheme="red" onClick={openChainModal}>
                    Wrong network
                  </Button>
                );
              }

              return (
                <div style={{ display: "flex", gap: 12 }}>
                  {chainButton(chain)}

                  <Button onClick={openAccountModal} colorScheme="blue">
                    {account.displayName}
                  </Button>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButtonRainbow.Custom>
  );
};
