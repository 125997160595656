import { motion } from "framer-motion";
import {
  Text,
  Heading,
  Flex,
  SimpleGrid,
  Box,
  VStack,
  CircularProgress,
  CircularProgressLabel,
  UnorderedList,
  ListItem,
  Link,
} from "@chakra-ui/react";
import { ReactElement } from "react";
import { Layout } from "components/layout";
import { Card } from "components/common/Card/Card";
import { BsCircleFill } from "react-icons/bs";
import { environments } from "environment/environment";
import { Fieldset } from "components/common/Fieldset/Fieldset";
import { toHHMMSS } from "utils/utils";
import { BiRefresh } from "react-icons/bi";

const Info = () => {
  return (
    <Flex
      direction="column"
      gap="5"
      minH="full"
      justifyContent="center"
      alignItems="center"
    >
      <Card
        w="full"
        mt="5"
        type="shadowed"
        as={motion.div}
        initial={{ scaleY: 0 }}
        animate={{ scaleY: 1 }}
        transition=" 1s easeIn"
      >
        <Heading as="h1" textAlign="center">
          How to play
        </Heading>
      </Card>
      <Card
        as={motion.div}
        initial={{ translateY: 1000 }}
        animate={{ translateY: 0 }}
        transition="1s easeIn"
      >
        <Text mb={4}>
          Welcome to LASTISME! In this dapp, any user can create a game with
          customized parameters, including the maximum duration of each round,
          whether the game has a maximum number of rounds or is infinite, the
          percentage by which the ticket price can increase and how often, the
          base price of the ticket, and how much time each ticket purchased adds
          to the round&apos;s duration.{" "}
        </Text>
        <Text mb={4}>
          Participants can purchase a ticket for each round until the
          round&apos;s time expires. The last player to purchase a ticket when
          time runs out wins the round and receives the majority of the prize at
          stake. A small portion of the prize is added to the jackpot of the
          next round (if applicable).{" "}
        </Text>
        <Text fontSize="xl" mb={4}>
          This game was originally born from the idea of this{" "}
          <Link
            href="https://bitcointalk.org/index.php?topic=1439839.0"
            textUnderlineOffset="1"
          >
            <u>
              <b>post</b>
            </u>
          </Link>
          , we would like to thank the creator in particular.
        </Text>
        <Text fontSize="xl" as="b" mb={4}>
          Good Luck!!
        </Text>
      </Card>
      <Card
        w="full"
        as={motion.div}
        initial={{ translateY: 1000 }}
        animate={{ translateY: 0 }}
        transition="1s easeIn"
      >
        <Heading as="h2">House</Heading>
        <Text mb="3">
          The house is the creator entity of the dapp and of the rules to create
          new games, the house games can keep updating the rounds even if the
          jackpot is less than the house rules{" "}
        </Text>
        <Text mb="8">
          The house rules are constraints on the minimum values applicable to
          each game
        </Text>
        <Fieldset title="House rules">
          <SimpleGrid columns={2} mt={4}>
            <Text>
              Minimum ticket price: {environments.houseRules.minTicketPrice}
            </Text>
            <Text>
              Minimum pot balance :
              {environments.houseRules.minPotBalanceForNewGames}
            </Text>
          </SimpleGrid>
          <SimpleGrid columns={2}>
            <Text>
              Game creator fee: {environments.houseRules.creatorFee} %
            </Text>
            <Text>Next pot fee: {environments.houseRules.nextPotFee} %</Text>
            <Text>House fee : {environments.houseRules.houseFee} % </Text>
          </SimpleGrid>
        </Fieldset>
      </Card>
      <Card
        w="full"
        as={motion.div}
        initial={{ translateY: 1000 }}
        animate={{ translateY: 0 }}
        transition="1s easeIn"
      >
        <Heading as="h2">Time</Heading>
        <Text mb="3">
          <b>Time</b> does not exist within the blockchain, in its place there
          are <b>blocks</b>, game time is divided into blocks as smart contracts
          perceive that as time
        </Text>
        <Text mb="3">
          The timers within the dapp are all approximations of real time based
          on the average of the latest blocks added to the blockchain and the
          block times of each individual game
        </Text>
        <Text as={Flex} gap="1">
          <BiRefresh color="green" fontSize="30" />{" "}
          <Text>
            This icon appears next to the timer when a new block has been added
            to the blockchain, or a change to the game state is detected and the
            timer is recalculated accordingly
          </Text>
        </Text>
        <Box>
          <Text textAlign="center" mb="3">
            <b>Example:</b>
          </Text>
          <Flex gap="5" justifyContent="center">
            <VStack>
              <Text>Round duration: 30 blocks</Text>
              <Text>Network speed avg.: 10s</Text>
              <Text>Passed blocks: 10 blocks</Text>
              <Text>Remaining time: ~ {toHHMMSS(200)}</Text>
            </VStack>
            <Box>
              <CircularProgress
                color={"blue"}
                value={(100 / 300) * 100}
                size="130px"
              >
                <CircularProgressLabel fontSize="15px">
                  ~ {toHHMMSS(200)}
                </CircularProgressLabel>
              </CircularProgress>
            </Box>
          </Flex>
        </Box>
      </Card>
      <Flex
        gap="5"
        direction={{ base: "column", md: "row" }}
        as={motion.div}
        initial={{ translateY: 1000 }}
        animate={{ translateY: 0 }}
        transition="1s easeIn"
      >
        <Card type="special">
          <Heading as="h2">Game rules</Heading>
          <Text mb={3}>
            <b>Ticket price</b> = Price of the ticket that this is not increased
            with the subsequent rules
          </Text>
          <Text mb={3}>
            <b>Round base duration</b> = Base round duration (in blocks) after
            which the round expires, with no ticket bought
          </Text>
          <Text mb={3}>
            <b>Pot balance</b> = base jackpot for the game
          </Text>
          <Text mb={3}>
            <b>Ticket step per block</b> = By how many blocks the duration of
            the round is increased after the purchase of the ticket
          </Text>
          <Text mb={3}>
            <b>Max step per block</b> = The maximum number of blocks by which
            the duration can be increased by the purchase of tickets in the same
            block
          </Text>
          <Text mb={3}>
            <b>Max rounds</b> = Max Rounds of the game (0 is for Infinite)
          </Text>
          <Text mb={3}>
            <b>Ticket increment value</b> = By how much to increase the ticket
            as a percentage of the current price
          </Text>
          <Text mb={3}>
            <b>Ticket increment step</b>= Every how many blocks to increase the
            price of the ticket
          </Text>
        </Card>
        <Card type="special">
          <Heading> Game States </Heading>
          <Flex>
            <Flex direction="column" gap="5">
              <Flex gap="2">
                <Box as={Flex} alignItems="center" color="gray.300" gap="2">
                  <BsCircleFill></BsCircleFill>
                </Box>
                <Text>Not started = When a round is not started yet</Text>
              </Flex>
              <Flex gap="2">
                <Box as={Flex} alignItems="center" color="green.500" gap="2">
                  <BsCircleFill></BsCircleFill>
                </Box>
                <Text>Started = When a round is started</Text>
              </Flex>
              <Flex gap="2">
                <Box as={Flex} alignItems="center" color="yellow.500" gap="2">
                  <BsCircleFill></BsCircleFill>
                </Box>
                <Text>
                  Expired = When a round is expired until the call for endRound
                  is triggered
                </Text>
              </Flex>
              <Flex gap="2">
                <Box as={Flex} alignItems="center" color="red.500" gap="2">
                  <BsCircleFill></BsCircleFill>
                </Box>
                <Text>Finished = When the game is finished</Text>
              </Flex>
            </Flex>
          </Flex>
        </Card>
      </Flex>
      <Card
        w="full"
        as={motion.div}
        initial={{ translateY: 1000 }}
        animate={{ translateY: 0 }}
        transition="1s easeIn"
      >
        <Heading as="h2">Game Start conditions</Heading>
        <Text mb={3}>
          These condition are setted in the time the game will be created
        </Text>
        <UnorderedList>
          <ListItem>Start the game with the first ticket</ListItem>
          <ListItem>Start the game after a certain number of blocks</ListItem>
        </UnorderedList>
      </Card>
      <Card
        w="full"
        as={motion.div}
        initial={{ translateY: 1000 }}
        animate={{ translateY: 0 }}
        transition="1s easeIn"
      >
        <Heading as="h2">Game ending conditions</Heading>
        <Text mb={3}>
          There are a number of conditions whereby the game ends at the end of
          the current round
        </Text>
        <UnorderedList>
          <ListItem>
            If the jackpot for the next round is lesser than the minimum pot
            (house rule)
          </ListItem>
          <ListItem>
            If the jackpot for the next round is lesser than the minimum ticket
            price (house rule)
          </ListItem>
          <ListItem>
            If the current round id is equal to max round in game settings
          </ListItem>
        </UnorderedList>
      </Card>
    </Flex>
  );
};

Info.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>;
};
export default Info;
