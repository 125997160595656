import { useContext } from "react";
import { EtherContext } from "services/etherProvider";
import { Flex, Skeleton, Text, Box, Heading } from "@chakra-ui/react";
import { GameContext } from "services/gameProvider";
import { useAccount } from "wagmi";
import { BuyButton } from "components/common/GameButtons/BuyButton";
import { EndButton } from "components/common/GameButtons/EndButton";
import { usePriceProvider } from "services/priceProvder";
import { KillButton } from "components/common/GameButtons/KillButton";
import { FundGameButton } from "components/common/GameButtons/FundGameButton";
export const GameActions = () => {
  const { address } = useAccount();
  const { priceFeed } = usePriceProvider();

  const { game, reloadGame } = useContext(GameContext);
  const { etherHelper } = useContext(EtherContext);
  return (
    <>
      <Box
        position={{ md: "absolute" }}
        as={Flex}
        w="full"
        mt={{ base: "3", md: "0" }}
        gap="3"
        justifyContent={{ base: "center", md: "left" }}
      >
        {game !== null &&
          game.active &&
          address &&
          game.creator.toLowerCase() == address.toLowerCase() && (
            <>
              <KillButton
                gameData={game}
                reloadGame={reloadGame}
                large={true}
              ></KillButton>

              <FundGameButton
                game={game}
                refreshState={reloadGame}
                etherHelper={etherHelper}
              ></FundGameButton>
            </>
          )}
      </Box>
      <Flex p={5} justifyContent="center" alignItems="center" gap={4}>
        {game ? (
          !game?.currentRound.expired ? (
            game?.active && (
              <BuyButton
                gameData={game}
                reloadGame={reloadGame}
                large={true}
              ></BuyButton>
            )
          ) : game?.active ? (
            <EndButton
              gameData={game}
              reloadGame={reloadGame}
              large={true}
            ></EndButton>
          ) : (
            <Heading as="h2">Game ended</Heading>
          )
        ) : (
          <Skeleton height="2rem" width="4rem" />
        )}
      </Flex>
    </>
  );
};
