import { createContext, useEffect, useState } from "react";

export const STARRED_KEY = "STARRED_GAMES_LIM";
export const PLAYED_KEY = "PLAYED_GAMES_LIM";

export const UserContext = createContext<{
  starred: StorageGames;
  played: StorageGames;
}>({
  starred: {
    games: [],
    reload() {},
  },
  played: {
    games: [],
    reload() {},
  },
});

export interface StorageGames {
  games: number[];
  addToGames?: (newId: number) => void;
  removeFromGames?: (oldId: number) => void;
  reload: () => void;
}

export class StorageHandler {
  static loadStorage(key: string) {
    if (typeof window !== "undefined") {
      // Perform localStorage action
      let obj = localStorage.getItem(key);

      if (obj != null) {
        return JSON.parse(obj);
      } else {
        return [];
      }
    } else {
      return [];
    }
  }
  static updateStorage(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }
  static backupStorage(context: {
    starred: StorageGames;
    played: StorageGames;
  }) {
    const content = Buffer.from(JSON.stringify(context)).toString("base64");

    const file = new File(
      ["\ufeff" + content],
      "lastismedata_" + Date.now() + ".txt",
      {
        type: "text/plain:charset=UTF-8",
      }
    );

    let url = window.URL.createObjectURL(file);

    var a = document.createElement("a");

    a.href = url;
    a.download = file.name;
    a.click();
    window.URL.revokeObjectURL(url);
  }
  static restoreBackup(
    file: File,
    context: {
      starred: StorageGames;
      played: StorageGames;
    }
  ) {
    return new Promise<void>((res, rej) => {
      file.text().then((content) => {
        let backup = Buffer.from(content, "base64").toString("utf-8");
        let data = null;
        try {
          data = JSON.parse(backup);
        } catch (err) {
          rej(err);
        }
        StorageHandler.updateStorage(PLAYED_KEY, data.played.games);
        context.played.reload();
        StorageHandler.updateStorage(STARRED_KEY, data.starred.games);
        context.starred.reload();

        res();
      });
    });
  }
}

export const useStorageGames = (key: string): StorageGames => {
  const [games, setGames] = useState<number[]>(StorageHandler.loadStorage(key));
  const reload = () => {
    setGames(StorageHandler.loadStorage(key));
  };
  const addToGames = (newId: number) => {
    if (!games.includes(newId)) {
      let g = [newId, ...games];
      setGames(g);
    }
  };
  const removeFromGames = (oldId: number) => {
    let g = games.filter((el) => el !== oldId);
    setGames(g);
  };
  useEffect(() => {
    StorageHandler.updateStorage(key, games);
  }, [games, key]);
  return {
    games,
    addToGames,
    removeFromGames,
    reload,
  };
};
