import {
  Heading,
  Text,
  Box,
  VStack,
  Tag,
  Skeleton,
  Flex,
} from "@chakra-ui/react";
import { Card } from "components/common/Card/Card";
import { Fieldset } from "components/common/Fieldset/Fieldset";
import { RoundTimer } from "./RoundTimer";
import { useContext } from "react";
import { GameStatus } from "./GameStatus";
import { GameInfos } from "./GameInfos";
import { motion } from "framer-motion";
import { GameContext } from "services/gameProvider";
import { useBlockNumber } from "wagmi";

export default function GameDetails() {
  const { game } = useContext(GameContext);
  const { data: currentBlock, isLoading } = useBlockNumber({ watch: true });
  return (
    <Flex direction="column" gap="5">
      <Flex direction={{ base: "column", md: "row" }} gap="5">
        <Card
          mb={0}
          w="full"
          p={4}
          as={motion.div}
          initial={{ translateX: -1000 }}
          animate={{ translateX: 0 }}
          transition="1s easeIn"
        >
          {game ? <GameStatus></GameStatus> : <SkeletonInfo></SkeletonInfo>}
        </Card>
        {game ? (
          game.active && (
            <Card
              w="full"
              as={motion.div}
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition="1s easeIn"
            >
              <RoundTimer></RoundTimer>
            </Card>
          )
        ) : (
          <Card
            w="full"
            as={motion.div}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition="1s easeIn"
          >
            <SkeletonInfo></SkeletonInfo>
          </Card>
        )}
        <Card
          mb={0}
          p={4}
          w="full"
          minH="120px"
          type="shadowed"
          as={motion.div}
          initial={{ translateX: 1000 }}
          animate={{ translateX: 0 }}
          transition="1s easeIn"
        >
          {game ? (
            <VStack>
              <Text fontSize="lg">Tickets</Text>
              <Text fontSize="3xl">{game?.currentRound.tickets}</Text>
              {!game?.currentRound.expired ? (
                <>
                  <Text>Last</Text>
                  {game?.currentRound.last.map((el, i) => {
                    return (
                      <Tag key={i}>
                        {" "}
                        {el.slice(0, 9) + "..." + el.slice(-7)}{" "}
                      </Tag>
                    );
                  })}
                </>
              ) : (
                <>
                  <Text>Winner</Text>
                  <Tag variant="solid" colorScheme="green">
                    {game.currentRound.winner.slice(0, 9) +
                      "..." +
                      game.currentRound.winner.slice(-7)}
                  </Tag>
                </>
              )}
            </VStack>
          ) : (
            <SkeletonInfo></SkeletonInfo>
          )}
        </Card>
      </Flex>

      <Flex direction={{ base: "column", md: "row" }} gap="5">
        <Fieldset
          title="Game Rules"
          w="full"
          as={motion.div}
          initial={{ scaleY: 0 }}
          animate={{ scaleY: 1 }}
          transition="1s easeIn"
        >
          <Card mb={0} p={4} w="100%" height="full">
            {game ? <GameInfos></GameInfos> : <SkeletonInfo></SkeletonInfo>}
          </Card>
        </Fieldset>
        <Fieldset
          title="Current Block"
          w="full"
          as={motion.div}
          initial={{ scaleY: 0 }}
          animate={{ scaleY: 1 }}
          transition="1s easeIn"
        >
          <Card mb={0} p={4} w="full" h="full">
            {game && currentBlock ? (
              <Flex
                direction="column"
                justifyContent="center"
                h="100%"
                alignContent="center"
                alignItems="center"
                gap={4}
              >
                <Tag>Started at: #{game?.currentRound.startedAt} </Tag>

                <Heading>#{currentBlock?.toString()}</Heading>
                <Tag variant="subtle" color="blue">
                  Expire at: #{game?.currentRound.expiresAt}{" "}
                </Tag>
              </Flex>
            ) : (
              <SkeletonInfo></SkeletonInfo>
            )}
          </Card>
        </Fieldset>
      </Flex>
    </Flex>
  );
}

const SkeletonInfo = () => {
  return (
    <Box mb={0} p={4} w="100%" height="180px">
      <Skeleton height="20px" mb="5" />
      <Skeleton height="20px" mb="5" />

      <Skeleton height="20px" mb="5" />
    </Box>
  );
};
