import { Box, Flex, Heading } from "@chakra-ui/react";
import { Button } from "components/common/Button/Button";
import { Link } from "react-router-dom";

import Typewriter from "typewriter-effect";

const HomePage = () => {
  return (
    <Flex
      as="header"
      p={4}
      mb={10}
      alignItems="center"
      justifyContent="center"
      direction="column"
      minH="95vh"
      gap="5"
    >
      <Box boxShadow="md" borderWidth="2px" borderColor="black" bg="white">
        <Heading cursor="pointer" fontSize={{ base: 85, md: 150, lg: 200 }}>
          {" "}
          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .typeString("LASTISME")
                .callFunction(() => {
                  typewriter.stop();
                })

                .start();
            }}
            options={{ autoStart: true, loop: false }}
          ></Typewriter>{" "}
        </Heading>
      </Box>
      <Flex gap="5">
        <Link to="/dapp">
          <Button size="lg" colorScheme="blue">
            Enter Dapp
          </Button>
        </Link>
        <Link to="/info">
          <Button size="lg">How to play</Button>
        </Link>
      </Flex>
    </Flex>
  );
};

export default HomePage;
