import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { EtherContext } from "./etherProvider";
const aggregatorV3InterfaceABI = [
  {
    inputs: [],
    name: "decimals",
    outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "description",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint80", name: "_roundId", type: "uint80" }],
    name: "getRoundData",
    outputs: [
      { internalType: "uint80", name: "roundId", type: "uint80" },
      { internalType: "int256", name: "answer", type: "int256" },
      { internalType: "uint256", name: "startedAt", type: "uint256" },
      { internalType: "uint256", name: "updatedAt", type: "uint256" },
      { internalType: "uint80", name: "answeredInRound", type: "uint80" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "latestRoundData",
    outputs: [
      { internalType: "uint80", name: "roundId", type: "uint80" },
      { internalType: "int256", name: "answer", type: "int256" },
      { internalType: "uint256", name: "startedAt", type: "uint256" },
      { internalType: "uint256", name: "updatedAt", type: "uint256" },
      { internalType: "uint80", name: "answeredInRound", type: "uint80" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "version",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
];
export const PriceFeedContext = createContext<{
  priceFeed: number;
}>({ priceFeed: 0 });

interface PriceProvider {
  getLastRoundData: () => void;
}

export function usePriceProvider() {
  const NativeUsdt = process.env.REACT_APP_AVALANCHE_PRICE_FEED;
  const { etherHelper } = useContext(EtherContext);
  const [priceFeed, setPriceFeed] = useState(0);
  const [decimals, setDecimals] = useState(8);
  useEffect(() => {
    if (etherHelper) {
      etherHelper
        ?.call("decimals", null, {
          address: NativeUsdt,
          abi: aggregatorV3InterfaceABI,
        })
        .then((a: any) => {
          setDecimals(a);
          reloadPriceFeed();
        });
    }
  });

  const reloadPriceFeed = useCallback(() => {
    etherHelper
      ?.call("latestRoundData", null, {
        address: NativeUsdt,
        abi: aggregatorV3InterfaceABI,
      })
      .then((a: any) => {
        setPriceFeed(
          Number((a[1].toString() / Math.pow(10, decimals)).toFixed(2))
        );
      });
  }, [etherHelper]);

  return { priceFeed };
}
