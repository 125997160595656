import { Flex, useColorModeValue, Heading, Box } from "@chakra-ui/react";
import styles from "./topbar.module.scss";
import { Link } from "react-router-dom";
import { ConnectButton } from "../ConnectButton";
const siteTitle = "LASTISME";
export default function TopBar() {
  return (
    <>
      <Flex
        position="relative"
        zIndex="relative"
        className={styles["topbar"]}
        w="full"
        alignContent="center"
        alignItems="center"
        justifyContent="space-between"
        padding="3"
        mb={{ base: 10, md: 0 }}
        bg={useColorModeValue("gray.100", "gray.900")}
      >
        <Link to="/info">
          <u>
            <b>How to play</b>
          </u>
        </Link>

        <Flex
          left={0}
          as="header"
          p={4}
          mb={10}
          position="absolute"
          w="full"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            className={styles["brand-tag"]}
            top={{ base: 50, md: 25 }}
            boxShadow="md"
          >
            <Link to={"/dapp"}>
              <Heading cursor="pointer" size="2xl">
                {siteTitle}
              </Heading>
            </Link>
          </Box>
        </Flex>
        <ConnectButton></ConnectButton>
      </Flex>
    </>
  );
}
