import { Box } from "@chakra-ui/react"
import { useContext } from "react"
import { BsStar, BsStarFill } from "react-icons/bs"
import { UserContext } from "services/storageGamesProvider"


export const StarredGameButton = ({gameId,...rest}:{gameId:number})=>{
    const {starred,played} = useContext(UserContext) 

    return(
        <Box minW="16px">
        {starred.games.includes(gameId) ? (
          <BsStarFill
            color="orange"
            onClick={() => {
              starred.removeFromGames &&
                starred.removeFromGames(gameId);
            }}
          ></BsStarFill>
        ) : (
          <BsStar
            onClick={() => {
              starred.addToGames && starred.addToGames(gameId);
            }}
          ></BsStar>
        )}
      </Box>
    )
}