import { useContext, useEffect, useMemo, useState } from "react";
import { PriceFeedContext } from "services/priceProvder";
import { Flex, Text, Box, Tooltip, useToast, Heading } from "@chakra-ui/react";
import { EtherHelper } from "services/etherProvider";
import { formatMoney } from "utils/utils";
import internal from "stream";
export const PriceConversion = ({
  value,
  ...rest
}: {
  value: number | bigint | string;
}) => {
  const { priceFeed } = useContext(PriceFeedContext);

  const [internalValue, setInternalValue] = useState(0);

  useEffect(() => {
    if (typeof value === "bigint") {
      setInternalValue(parseFloat(EtherHelper.fromWei(value as bigint)));
    } else if (typeof value === "string") {
      setInternalValue(parseFloat(value as string));
    } else {
      setInternalValue(value);
    }
  }, [value]);
  const converted = useMemo(() => {
    return formatMoney(internalValue * priceFeed, 2, false);
  }, [internalValue, priceFeed]);
  return (
    <>
      <Text color="gray.500">({converted} $)</Text>
    </>
  );
};
