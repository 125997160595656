import { createStandaloneToast, useToast } from "@chakra-ui/react";

export function useMessageHandler() {
  const MessageHandler = createStandaloneToast({
    defaultOptions: {
      size: "xl",
      position: "top-left",
      variant: "top-accent",
    },
  });
  const invoke = (
    { message, title }: { message: string; title?: string },
    kind: "error" | "info" | "success"
  ) => {
    MessageHandler.toast({
      title: title ? title : kind.toLocaleUpperCase(),
      variant: "top-accent",
      description: message,
      status: kind,
      isClosable: false,
      duration: 9000,
    });
  };
  return {
    invoke,
  };
}
