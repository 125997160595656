import {
  TableContainer,
  Table,
  Tbody,
  Thead,
  Tr,
  Td,
  Th,
  Flex,
} from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useState } from "react";
import { IGameRound } from "interfaces/IGame";
import { EtherContext, EtherHelper } from "services/etherProvider";
import { GrTicket } from "react-icons/gr";
import { AvaxIcon } from "components/common/CustomIcons/AvaxIcon";
import { formatMoney } from "utils/utils";
import { Paginate } from "react-paginate-chakra-ui";
import { GameContext } from "services/gameProvider";

const ELEMENT_PER_PAGE = 5;

export default function RoundList() {
  const { etherHelper } = useContext(EtherContext);
  const { game } = useContext(GameContext);

  const [roundList, setRoundList] = useState<IGameRound[]>([]);

  const [page, setPage] = useState(0);

  const recursiveRoundRetrieve = useCallback(
    (_roundId: number) => {
      if (
        _roundId > 0 &&
        !roundList.find((r) => {
          return r.roundId == _roundId;
        })
      ) {
        etherHelper
          ?.call("getGameRound", {
            _gameId: game?.gameId,
            _gameRound: _roundId,
          })
          .then((el: IGameRound) => {
            setRoundList([...roundList, el]);
            if (el.prevRoundId > 0) {
              recursiveRoundRetrieve(el.prevRoundId);
            }
          })
          .catch((err) => {});
      }
    },
    [etherHelper, game?.gameId, roundList]
  );
  const retrivePassedRounds = useCallback(
    async (tmpRound: IGameRound) => {
      let list: any[] = [];
      while (tmpRound.prevRoundId > 0) {
        let el = await etherHelper?.call("getGameRound", {
          _gameId: game?.gameId,
          _gameRound: tmpRound.prevRoundId,
        });
        tmpRound = el;
        list.push(tmpRound);
      }
      setRoundList(list);
    },
    [etherHelper, game?.gameId]
  );
  useEffect(() => {
    if (game) {
      let list: any[] = [];
      if (game.currentRound.prevRoundId > 0) {
        let tmpRound = game.currentRound;
        retrivePassedRounds(tmpRound);
      }
    }
  }, [etherHelper, game, retrivePassedRounds]);
  const handlePageClick = (event: number) => {
    setPage(event);
  };
  return (
    <>
      {roundList.length > ELEMENT_PER_PAGE && (
        <Paginate
          page={page}
          count={roundList.length}
          pageSize={ELEMENT_PER_PAGE}
          onPageChange={handlePageClick}
          // optional props 👇
          margin={2}
          shadow="lg"
          fontWeight="blue"
          variant="outline"
          // ...border and other props also work 💪
          border="2px solid"
          // you can use w to adjust to parent
          // container
          w="full"
        ></Paginate>
      )}
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Started at</Th>
              <Th>Expired block</Th>
              <Th>Tickets</Th>
              <Th>Paid</Th>
              <Th>Winner</Th>
            </Tr>
          </Thead>

          <Tbody>
            {roundList
              .filter((r) => !/^0x0+$/.test(r.winner))
              ?.slice(page * ELEMENT_PER_PAGE, (page + 1) * ELEMENT_PER_PAGE)
              .map((r: any, idx: number) => {
                return (
                  r.roundId != 0 && (
                    <Tr key={idx}>
                      <Td>{r.roundId}</Td>
                      <Td>{r.startedAt}</Td>
                      <Td>{r.expiresAt}</Td>
                      <Td>
                        <Flex alignContent="center" gap="1" alignItems="center">
                          <GrTicket></GrTicket>
                          {r.tickets}
                        </Flex>
                      </Td>
                      <Td>
                        <Flex alignContent="center" gap="1" alignItems="center">
                          <AvaxIcon></AvaxIcon>
                          {formatMoney(EtherHelper.fromWei(r.paidBalance))}
                        </Flex>
                      </Td>
                      <Td>
                        {r.winner.slice(0, 9) + "..." + r.winner.slice(-7)}
                      </Td>
                    </Tr>
                  )
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}
