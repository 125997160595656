import {
  Text,
  Flex,
  SimpleGrid,
  Skeleton,
  Heading,
  Badge,
  Divider,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { Card } from "components/common/Card/Card";
import { HouseRulesContext } from "services/houserulesProvider";
import { Chain, useChainModal } from "@rainbow-me/rainbowkit";
import { PriceConversion } from "components/common/PriceConversion";
import { AvaxIcon } from "components/common/CustomIcons/AvaxIcon";
import { toHHMMSS } from "utils/utils";
import { EtherContext } from "services/etherProvider";
import { useAccount, useBlockNumber } from "wagmi";
import { EstimationInfo } from "components/common/EstimationInfo";
import { supportedChains } from "services/wallets";
import { GrSwitch } from "react-icons/gr";
import { Button } from "components/common/Button/Button";
import { ChainSelector } from "components/common/ChainSelector";
export const HouseRulesCard = ({ chain }: { chain: any }) => {
  const { houseRules } = useContext(HouseRulesContext);
  const { etherHelper } = useContext(EtherContext);
  const [networkSpeed, setNetworkSpeed] = useState(0);
  const { isConnected } = useAccount();
  const { data: currentBlock } = useBlockNumber({ watch: true });

  useEffect(() => {
    if (currentBlock) {
      etherHelper?.getNetworkSpeed(currentBlock).then((e) => {
        setNetworkSpeed(e.speed);
      });
    }
  }, [etherHelper, currentBlock]);
  return (
    <Card title="House rules" type="special" w="full">
      <Heading as="h2">
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          my="3"
        >
          <Flex>
            {"Connected to " + chain?.name}{" "}
            {/* {!isConnected && <ChainSelector></ChainSelector>} */}
          </Flex>
          {chain && chain.testnet && (
            <Badge
              size="xl"
              colorScheme="orange"
              textAlign="center"
              width="full"
            >
              testnet
            </Badge>
          )}
        </Flex>
      </Heading>
      <SimpleGrid
        columns={4}
        mt={4}
        gap="2"
        alignContent="center"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Text>Minimum ticket price</Text>

        {houseRules ? (
          <Flex direction="column">
            <Flex gap="1" alignItems="center">
              <Text as="b"> {houseRules.minTicketPrice}</Text>
              <AvaxIcon />
            </Flex>
            <PriceConversion
              value={houseRules.minTicketPrice}
            ></PriceConversion>
          </Flex>
        ) : (
          <Skeleton h="5" w="5"></Skeleton>
        )}

        <Text>Minimum pot balance</Text>

        {houseRules ? (
          <Flex direction="column">
            <Flex gap="1" alignItems="center">
              <Text as="b"> {houseRules.minPotBalanceForNewGames} </Text>
              <AvaxIcon />
            </Flex>
            <PriceConversion
              value={houseRules.minTicketPrice}
            ></PriceConversion>
          </Flex>
        ) : (
          <Skeleton h="5" w="5"></Skeleton>
        )}

        <Text>Minimum round duration</Text>

        {houseRules ? (
          <Flex direction="column">
            <Text as="b">#{houseRules.minDuration}</Text>
            <Flex gap="1" alignItems="center">
              {toHHMMSS(houseRules.minDuration * networkSpeed)}
              <EstimationInfo networkSpeed={networkSpeed}></EstimationInfo>
            </Flex>
          </Flex>
        ) : (
          <Skeleton h="5" w="5"></Skeleton>
        )}

        <Text>Minimum price increment duration </Text>

        {houseRules ? (
          <Flex direction="column">
            <Text as="b">#{houseRules.minPriceIncrementDuration}</Text>
            <Flex gap="1" alignItems="center">
              {toHHMMSS(houseRules.minDuration * networkSpeed)}
              <EstimationInfo networkSpeed={networkSpeed}></EstimationInfo>
            </Flex>
          </Flex>
        ) : (
          <Skeleton h="5" w="5"></Skeleton>
        )}
      </SimpleGrid>
      <Divider variant="dashed" borderColor="black" my="2"></Divider>
      <SimpleGrid columns={4} mt={3} gap="2">
        <Text>Game creator fee</Text>

        {houseRules ? (
          <Text as="b">{houseRules.creatorFee}%</Text>
        ) : (
          <Skeleton h="5" w="5"></Skeleton>
        )}

        <Text>Next pot fee</Text>

        {houseRules ? (
          <Text as="b">{houseRules.nextPotFee}%</Text>
        ) : (
          <Skeleton h="5" w="5"></Skeleton>
        )}
        <Text>House fee</Text>

        {houseRules ? (
          <Text as="b">{houseRules.houseFee}%</Text>
        ) : (
          <Skeleton h="5" w="5"></Skeleton>
        )}
      </SimpleGrid>
    </Card>
  );
};
