import { EstimateDuration } from "./EstimateDuration";

export const EstimateRoundDuration = ({
  layout,
}: {
  layout?: "fancy" | "mini" | "normal";
}) => {
  return (
    <EstimateDuration
      layout={layout}
      color="blue"
      calculateDelta={(g, b) => {
        
        return b
          ? b <= g.currentRound.startedAt
            ? g.currentRound.startedAt - Number(b)
            : g.currentRound.expiresAt - Number(b)
          : 0;
      }}
      calculateTotal={(g) => {
        return g ? g.currentRound.expiresAt - g.currentRound.startedAt : 0;
      }}
    ></EstimateDuration>
  );
};
