export function toHHMMSS(numSecs: number) {
  let secNum = parseInt(numSecs.toString(), 10);
  let hours = Math.floor(secNum / 3600)
    .toString()
    .padStart(2, "0");
  let minutes = Math.floor((secNum - parseInt(hours) * 3600) / 60)
    .toString()
    .padStart(2, "0");
  let seconds = (secNum - parseInt(hours) * 3600 - parseInt(minutes) * 60)
    .toString()
    .padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
}

export function formatMoney(
  money: string | number,
  decimals = 3,
  extended = true
) {
  let price = money;
  if (price.toString().length > 8) {
    if (typeof price == "string") {
      price = parseFloat(price);
    }

    if (price.toFixed(decimals) !== price.toString()) {
      return price.toFixed(decimals) + (extended ? "..." : "");
    } else {
      return price;
    }
  } else {
    return price;
  }
}

export function hexStringToUint8Array(hexString: string) {
  if (hexString.length % 2 !== 0) {
    throw new Error("Invalid hex string");
  }

  var bytes = new Uint8Array(Math.ceil(hexString.length / 2));

  for (var i = 0; i < bytes.length; i++) {
    bytes[i] = parseInt(hexString.substr(i * 2, 2), 16);
  }

  return bytes;
}
