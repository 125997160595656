import { ButtonProps, Skeleton, Tooltip, Text, Flex } from "@chakra-ui/react";
import { useAddRecentTransaction } from "@rainbow-me/rainbowkit";
import { Button } from "components/common/Button/Button";
import { AvaxIcon } from "components/common/CustomIcons/AvaxIcon";
import { IGame } from "interfaces/IGame";
import { useContext } from "react";
import { GrTicket } from "react-icons/gr";
import { EtherContext, EtherHelper } from "services/etherProvider";
import { UserContext } from "services/storageGamesProvider";
import Typewriter from "typewriter-effect";
import { motion } from "framer-motion";

export const BuyButton = ({
  gameData,
  reloadGame,
  large,
  ...rest
}: ButtonProps & {
  gameData: IGame;
  reloadGame: () => void;
  large?: boolean;
}) => {
  const { played } = useContext(UserContext);
  const { etherHelper } = useContext(EtherContext);
  const addRecentTransaction = useAddRecentTransaction();

  const buyATicket = () => {
    const roundId = !gameData?.currentRound.expired
      ? gameData?.currentRound.roundId
      : gameData?.currentRound.roundId;
    etherHelper
      ?.send(
        "buyGameTicket",
        {
          _gameId: gameData?.gameId,
          _gameRound: roundId,
        },
        gameData?.currentTicketPrice
      )
      .then((hash: any) => {
        played.addToGames && played.addToGames(gameData?.gameId!);
        addRecentTransaction({
          hash: hash,
          description:
            "Buy a ticket for game #" +
            gameData.gameId +
            " round #" +
            roundId +
            " for " +
            EtherHelper.fromWei(gameData?.currentTicketPrice),
        });
        reloadGame();
      });
  };

  if (large) {
    return (
      <Button
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        cursor="pointer"
        whileHover={{ scale: [1, 1.2] }}
        transition={{
          duration: "1.8",
          delay: "1.5",
          ease: "0, 0.71, 0.2, 1.01",
        }}
        as={motion.div}
        h="70px"
        w="200px"
        onClick={buyATicket}
        {...rest}
      >
        <Flex direction="column" alignItems="center" gap="3">
          <Text>
            <Typewriter
              onInit={(typewriter) => {
                typewriter
                  .typeString("Buy a ticket")
                  .callFunction(() => {
                    typewriter.stop();
                  })

                  .start();
              }}
              options={{ autoStart: true, loop: false }}
            ></Typewriter>
          </Text>
          <Flex gap="1" alignItems="center" alignContent="center">
            <GrTicket></GrTicket>{" "}
            {etherHelper ? (
              EtherHelper.fromWei(gameData?.currentTicketPrice)
            ) : (
              <Skeleton height="4px" />
            )}
            <AvaxIcon />
          </Flex>
        </Flex>
      </Button>
    );
  } else {
    return (
      <Tooltip
        placement="top"
        hasArrow
        label={`Buy a ticket for ${EtherHelper.fromWei(
          gameData.currentTicketPrice
        )} AVAX`}
      >
        <Button
          cursor="pointer"
          whileHover={{ scale: [1, 1.2] }}
          transition={{
            duration: "1.8",
            delay: "1.5",
            ease: "0, 0.71, 0.2, 1.01",
          }}
          as={motion.div}
          variant="solid"
          onClick={() => {
            buyATicket();
          }}
        >
          <GrTicket></GrTicket>
        </Button>
      </Tooltip>
    );
  }
};
