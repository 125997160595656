import { createContext, useCallback, useEffect, useState } from "react";
import { EtherHelper } from "./etherProvider";


interface IHouseRules{
    houseFee: number;
    creatorFee: number;
    nextPotFee: number;
    minTicketPrice: number;
    minPotBalanceForNewGames: number;

    minDuration: number,
    minPriceIncrementDuration: number,
}

export const HouseRulesContext = createContext<{ houseRules: IHouseRules | null, reloadHouseRules: () => void }>({ houseRules: null, reloadHouseRules: () => { } })
export const useHouseRulesProvider = (etherHelper?: EtherHelper,hr:IHouseRules|null=null) => {

    const [houseRules, setHouseRules] = useState<IHouseRules | null>(hr)
    const reloadHouseRules = useCallback(() => {
            if(etherHelper){
                etherHelper?.call("getHouseRules").then(g => { 
                   
                    setHouseRules({
                        houseFee: g.houseFee,
                        creatorFee: g.creatorFee,
                        nextPotFee: g.nextPotFee,

                        minDuration: g.minDuration,
                        minPriceIncrementDuration: g.minPriceIncrementDuration,
                        minTicketPrice: parseFloat(EtherHelper.fromWei(g.minTicketPrice)),
                        minPotBalanceForNewGames: parseFloat( EtherHelper.fromWei(g.minPotBalanceForNewGames))
                    
                    
                    })

     
            })
   
            }
          
       
    }, [etherHelper])

    useEffect(() => {
        if(houseRules===null){
            reloadHouseRules()

        }

    
    }, [etherHelper])

    return {
        houseRules: houseRules,
        reloadHouseRules: reloadHouseRules
    }
}