import {
  Flex,
  Text,
  Skeleton,
  Tooltip,
  CircularProgress,
  CircularProgressLabel,
} from "@chakra-ui/react";
import { IGame } from "interfaces/IGame";
import { useContext, useEffect, useState } from "react";
import { EtherContext } from "services/etherProvider";
import { BiInfoCircle, BiRefresh } from "react-icons/bi";
import { toHHMMSS } from "utils/utils";
import { GameContext } from "services/gameProvider";
import { motion, useAnimate } from "framer-motion";
import { useBlockNumber } from "wagmi";
import { TimeIcon } from "@chakra-ui/icons";

export const EstimateDuration = ({
  layout,
  color,
  calculateDelta,
  calculateTotal,
}: {
  layout?: "fancy" | "mini" | "normal";
  calculateDelta: (g: IGame, b: bigint) => number;
  color?: "multi" | string;
  calculateTotal: (g: IGame) => number;
}) => {
  const [duration, setDuration] = useState(-1);
  const [roundTotalDuration, setRoundTotalDuration] = useState(-1);
  const [deadline, setDeadline] = useState(-1);
  const [isUpdating, setIsUpdating] = useState(false);
  const { etherHelper } = useContext(EtherContext);
  const { game, reloadGame } = useContext(GameContext);
  const [scope, animate] = useAnimate();
  const [networkSpeed, setNetworkSpeed] = useState(0);
  const { data: currentBlock } = useBlockNumber({ watch: true });

  useEffect(() => {
    const interval = setInterval(() => {
      let duration = (deadline - Date.now()) / 1000;
      setDuration(duration);
      if (
        duration < 0 &&
        game?.active &&
        !game.currentRound.expired &&
        game.currentRound.startedAt > 0
      ) {
        reloadGame();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [deadline, reloadGame]);

  useEffect(() => {
    if (
      etherHelper &&
      game &&
      currentBlock != null &&
      game.currentRound.startedAt !== 0
    ) {
      const delta = Math.floor(calculateDelta(game, currentBlock as bigint));
      const total = Math.floor(calculateTotal(game));
      etherHelper
        .getNetworkSpeed(currentBlock)
        .then(
          (network: { speed: number; lastBlock: any; timeDrift: number }) => {
            if (network.lastBlock != null) {
              const timeSinceLastBlock =
                Math.floor(Date.now() / 1000) -
                Number(network.lastBlock!.timestamp) -
                network.timeDrift -
                network.speed;
              const untilEnd = delta * network.speed - timeSinceLastBlock;

              setDeadline(Date.now() + untilEnd * 1000);
              setDuration(untilEnd);
              if (scope.current != null) {
                setIsUpdating(true);
                animate(scope.current, {
                  opacity: [0, 0.5, 1, 0.5, 0, 1],
                  transition: { duration: 2 },
                }).then(() => {
                  setIsUpdating(false);
                });
              }

              setRoundTotalDuration(total * network.speed);
              setNetworkSpeed(network.speed);
            }
          }
        );
    }
  }, [etherHelper, game, calculateDelta, calculateTotal, animate]);

  const progressValue = () => {
    return game?.active && !game?.currentRound.expired
      ? ((roundTotalDuration - duration) / roundTotalDuration) * 100
      : 0;
  };
  const getColor = () => {
    if (color === "multi") {
      return progressValue() < 60
        ? "green"
        : progressValue() < 90
        ? "orange"
        : "red";
    } else {
      return color;
    }
  };
  const timer = () => (
    <>
      {duration > 0 && !game?.currentRound.expired ? (
        <Flex
          gap="1"
          direction={layout == "fancy" ? "column" : "row"}
          justifyItems="center"
          alignItems="center"
          alignContent="center"
        >
          {currentBlock &&
          game &&
          game.currentRound.startedAt > currentBlock ? (
            <Tooltip
              placement="top"
              hasArrow
              label={"Start at #" + game.currentRound.startedAt}
            >
              <Text as={motion.div} ref={scope} color="gray.400">
                <Flex gap="1" alignItems="center">
                  <TimeIcon></TimeIcon> {"~ " + toHHMMSS(duration)}
                </Flex>
              </Text>
            </Tooltip>
          ) : (
            <Tooltip placement="top" hasArrow label="Time to round end">
              <Text as={motion.div} ref={scope}>
                {"~ " + toHHMMSS(duration)}
              </Text>
            </Tooltip>
          )}

          <Flex gap="2">
            <Tooltip
              placement="top"
              hasArrow
              label={"speed: ~ " + networkSpeed.toFixed(3) + "s"}
            >
              <span>
                <BiInfoCircle color="blue" />
              </span>
            </Tooltip>
            {isUpdating && layout && (
              <Tooltip placement="top" hasArrow label="Update in progress">
                <span>
                  <BiRefresh color="green" />
                </span>
              </Tooltip>
            )}
          </Flex>
        </Flex>
      ) : (
        <Flex
          gap="1"
          direction={layout == "fancy" ? "column" : "row"}
          justifyItems="center"
          alignItems="center"
          alignContent="center"
          px="2"
        >
          {game?.active && !game?.currentRound.expired ? (
            currentBlock &&
            (game.currentRound.startedAt == 0 ||
              game.currentRound.startedAt > currentBlock) ? (
              "Game not started"
            ) : (
              <Skeleton height="20px" width="50px"></Skeleton>
            )
          ) : game?.currentRound.expired ? (
            "Expired"
          ) : (
            "Finished"
          )}
        </Flex>
      )}
    </>
  );

  switch (layout) {
    case "fancy":
      if (
        currentBlock !== undefined &&
        game &&
        (game.currentRound.startedAt == 0 ||
          game.currentRound.startedAt > currentBlock)
      ) {
        return <>{timer()}</>;
      } else {
        return (
          <>
            <CircularProgress
              color={color ? getColor() : "blue"}
              isIndeterminate={
                game?.active &&
                game.maxRounds == game.currentRound.roundId &&
                game.maxRounds != 0 &&
                game.currentRound.expired &&
                duration < 0
              }
              value={progressValue()}
              size="130px"
            >
              <CircularProgressLabel fontSize="15px">
                {timer()}
              </CircularProgressLabel>
            </CircularProgress>
          </>
        );
      }

    case "mini":
      return (
        <CircularProgress
          color={color ? getColor() : "blue"}
          isIndeterminate={
            game?.active &&
            currentBlock !== undefined &&
            (game.currentRound.startedAt === 0 ||
              game.currentRound.startedAt > currentBlock) &&
            game.maxRounds === game.currentRound.roundId &&
            game.maxRounds !== 0 &&
            game.currentRound.expired &&
            duration < 0
          }
          value={progressValue()}
          size="20px"
        >
          <CircularProgressLabel fontSize="15px"></CircularProgressLabel>
        </CircularProgress>
      );

    case "normal":
    default:
      return timer();
  }
};
