import "@rainbow-me/rainbowkit/styles.css";
import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import {
  argentWallet,
  trustWallet,
  ledgerWallet,
  phantomWallet,
  safeWallet,
  injectedWallet,
  metaMaskWallet,
  rabbyWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { configureChains, createConfig } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { braveWallet } from "@rainbow-me/rainbowkit/wallets";
import { avalancheFuji, localhost } from "viem/chains";
import { coreWallet } from "@rainbow-me/rainbowkit/wallets";
import { jsonRpcProvider } from "@wagmi/core/providers/jsonRpc";

// const local = { ...localhost, id: 31337 };

const ankrRpcs: any = {
  31337: "http://127.0.0.1:8545",
  43113: "https://rpc.ankr.com/avalanche_fuji",
};

//export const supportedChains = [local, avalancheFuji];
export const supportedChains = [avalancheFuji];
export const { chains, publicClient } = configureChains(
  supportedChains,

  [
    publicProvider(),
    jsonRpcProvider({
      rpc: (chain) => ({
        http: ankrRpcs[chain.id],
      }),
    }),
  ]
);
const projectId = "e1dd0a03a494d0c42f016c24d2ff38c7";

const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [
      injectedWallet({ chains }),
      coreWallet({ projectId, chains }),

      braveWallet({
        chains,
      }),
      metaMaskWallet({
        projectId,
        chains,
      }),
      trustWallet({ projectId, chains }),
      walletConnectWallet({ projectId, chains }),
      ledgerWallet({ projectId, chains }),
      // zerionWallet( {
      //   projectId,
      //   chains
      // })
    ],
  },
  {
    groupName: "Other",
    wallets: [
      argentWallet({ projectId, chains }),
      // strustWallet({ projectId, chains }),
      safeWallet({
        chains,
      }),
      phantomWallet({
        chains,
      }),
      rabbyWallet({ chains }),
    ],
  },
]);
export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});
