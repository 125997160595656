import { Heading } from "@chakra-ui/react";
import { IGame } from "interfaces/IGame";
import { useContext, useEffect, useState } from "react";
import { GameContext } from "services/gameProvider";
import { useBlockNumber } from "wagmi";

export const GameStatusText = () => {
  const { game } = useContext(GameContext)
  const number = useBlockNumber()
  const [text, setText] = useState("LOADING");
  useEffect(() => {
    if (!game) {
      setText("LOADING")
    } else if (game.currentRound.startedAt == 0 || game.currentRound.startedAt > (number.data ? number.data : 0)) {
      setText("NOT STARTED")
    }

    else if (!game?.active)
      setText("FINISHED")

    else if (game?.currentRound.expired)
      setText("EXPIRED")
    else
      setText("ACTIVE")
  }, [game])

  return (

    <Heading fontSize="1.5rem">
      {text}
    </Heading>
  )
};
 