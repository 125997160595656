// Importa le dipendenze necessarie

import { Flex, Badge, Spacer, Img } from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons"; // Supponendo che tu voglia usare l'icona della chat fornita da Chakra UI
import { BiLogOut } from "react-icons/bi";
import { Button } from "../Button/Button";
import { useNostr, useProfile } from "nostr-react";
import { NostrUserContext } from "./NostrUserContext";
import { useContext, useMemo } from "react";
import { nip19 } from "nostr-tools";

export function ChatHeader() {
  const { pubkey, seckey, logout, decodepubkey } = useContext(NostrUserContext);
  const npub = useMemo(() => {
    return decodepubkey(pubkey!);
  }, [pubkey]);
  const { data: userData } = useProfile({
    pubkey: pubkey as string,
  });

  const handleDownload = (key: any, fileName: string) => {
    const json = JSON.stringify(key);
    const blob = new Blob([json], { type: "application/json" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };

  return (
    <Flex gap="2" alignContent="space-between" justifyItems="center" my="1">
      {userData && (
        <Img src={userData.picture} borderRadius={999} height="20px"></Img>
      )}
      <Badge colorScheme="green">
        {userData
          ? userData.name
          : npub?.substring(0, 8) + "..." + npub?.substring(npub.length - 4)}
      </Badge>
      {seckey && (
        <Button
          size={"xs"}
          shadow="none"
          leftIcon={<DownloadIcon></DownloadIcon>}
          onClick={() =>
            handleDownload(
              { seckey: nip19.nsecEncode(seckey), pubkey: npub },
              "private_keys.json"
            )
          }
        >
          Backup Keys
        </Button>
      )}
      <Spacer></Spacer>
      <Button
        size={"xs"}
        shadow="none"
        leftIcon={<BiLogOut></BiLogOut>}
        onClick={() => {
          // rooms.leave(room.id.to_hex());
          logout();
        }}
      >
        Logout
      </Button>
    </Flex>
  );
}
