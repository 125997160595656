import { ConnectButton } from "./ConnectButton";
import { Flex, Box, Heading } from "@chakra-ui/react";

export const NotConnectedWallet = () => {
  return (
    <Flex
      direction="column"
      alignItems="center"
      alignContent="center"
      justifyContent="center"
      gap={10}
    >
      <Heading size="lg" textAlign="center">
        Connect your wallet to use this dapp
      </Heading>
      <Box>
        <ConnectButton></ConnectButton>
      </Box>
    </Flex>
  );
};
