import { Box, BoxProps, ChakraProps } from "@chakra-ui/react";
import { Fieldset } from "components/common/Fieldset/Fieldset";
import { ReactNode } from "react";
import styles from "./card.module.scss";

export interface CardProps
  extends JSX.IntrinsicAttributes,
    ChakraProps,
    BoxProps {
  title?: string;
  type?: "special" | "shadowed";
  children: ReactNode;
}

// const layouts = ["special"];
export function Card({ title, children, type, ...rest }: any) {
  return (
    <Box
      {...rest}
      className={
        type
          ? styles["card"] + " " + styles["card" + "-" + type]
          : styles["card"]
      }
    >
      {title ? (
        <Fieldset title={title}>{children}</Fieldset>
      ) : (
        <Box h="full" w="full">
          {children}
        </Box>
      )}
    </Box>
  );
}
