import { Flex, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { AvaxIcon } from "components/common/CustomIcons/AvaxIcon";
import { PriceConversion } from "components/common/PriceConversion";
import { useContext, useEffect, useState } from "react";
import { BiInfinite } from "react-icons/bi";
import { EtherContext, EtherHelper } from "services/etherProvider";
import { GameContext } from "services/gameProvider";
import { PriceFeedContext } from "services/priceProvder";
import { formatMoney } from "utils/utils";

export const GameInfos = () => {
  const [createdDate, setCreatedDate] = useState(new Date());
  const { etherHelper } = useContext(EtherContext);
  const { game } = useContext(GameContext);
  const { priceFeed } = useContext(PriceFeedContext);
  useEffect(() => {
    if (game) {
      etherHelper?.getBlock(BigInt(game.createdAt)).then((block) => {
        if (block) {
          setCreatedDate(new Date(Number(block.timestamp) * 1000));
        }
      });
    }
  }, []);
  return (
    <Table size="sx">
      <Thead>
        <Tr>
          <Th>Rule</Th>
          <Th w={{ base: "200px", md: "100px", lg: "350px" }}></Th>
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
          <Td>Round base duration</Td>
          <Td>#{game?.roundBaseDuration}</Td>
        </Tr>
        <Tr>
          <Td>Max step per block</Td>
          <Td>#{game?.maxStepPerBlock}</Td>
        </Tr>
        <Tr>
          <Td>Ticket duration step</Td>
          <Td>#{game?.ticketDurationStep}</Td>
        </Tr>

        <Tr>
          <Td>Max rounds</Td>
          <Td>
            {game && game.maxRounds > 0 ? game.maxRounds : <BiInfinite />}
          </Td>
        </Tr>
        <Tr>
          <Td>Ticket price</Td>
          <Td>
            <Flex gap="1" alignContent="center" alignItems="center">
              {formatMoney(EtherHelper.fromWei(game!.currentTicketPrice))}
              <AvaxIcon />
              <PriceConversion
                value={game!.currentTicketPrice}
              ></PriceConversion>
            </Flex>
          </Td>
        </Tr>
        {etherHelper && game && game.ticketPriceIncrementStep > 0 && (
          <Tr>
            <Td></Td>
            <Td>
              {parseFloat(EtherHelper.fromWei(game.ticketPriceIncrement)) *
                100 +
                "% every " +
                game?.ticketPriceIncrementStep +
                " blocks"}
            </Td>
          </Tr>
        )}
        <Tr>
          <Td>Game created at</Td>
          <Td>#{game?.createdAt} </Td>
        </Tr>
        <Tr>
          <Td></Td>
          <Td>{createdDate.toDateString()}</Td>
        </Tr>
        <Tr>
          <Td>Game started after</Td>
          <Td>
            {!game?.startRoundWithTicket
              ? "#" + game?.startRoundAfter + " blocks"
              : "Game started with first ticket"}
          </Td>
        </Tr>
        <Tr>
          <Td>Round renewal</Td>
          <Td>
            {game?.renewRoundWithGameRules
              ? "Played round will be renewed with game start rules"
              : "Played round automatically restart at the round end"}
          </Td>
        </Tr>
      </Tbody>
    </Table>
  );
};
