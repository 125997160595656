import LastIsMeServiceArtifact from "./abis/LastIsMeService.json";

const contracts = {
  LastIsMeService: {
    abi: LastIsMeServiceArtifact,
  },
};

const houseRules = {
  houseFee: 1,
  creatorFee: 2,
  nextPotFee: 5,
  minTicketPrice: "0.005",
  minPotBalanceForNewGames: "0.01",
};

function getEnv() {
  return {
    contracts: contracts,
    houseRules: houseRules,
  };
}

export const environments = getEnv();
