import { ReactElement, useContext, useEffect } from "react";
import { EtherContext, EtherHelper } from "services/etherProvider";
import { Flex, Heading, Skeleton, VStack, Text } from "@chakra-ui/react";
import GameDetails from "components/game/GameDetails";
import { Card } from "components/common/Card/Card";
import { Fieldset } from "components/common/Fieldset/Fieldset";
import { AnimatePresence, motion } from "framer-motion";
import { BiInfinite } from "react-icons/bi";
import { AvaxIcon } from "components/common/CustomIcons/AvaxIcon";
import { formatMoney } from "utils/utils";
import RoundList from "components/game/RoundList";
import { GameContext, GameProvider } from "services/gameProvider";
import { Layout } from "components/layout";
import { useAccount } from "wagmi";
import { useParams } from "react-router-dom";
import { PriceFeedContext, usePriceProvider } from "services/priceProvder";
import { MarqueeGameInfos } from "components/game/MarqueeGameInfo";
import { PriceConversion } from "components/common/PriceConversion";
import { NotConnectedWallet } from "components/common/NotConnectedWallet";
import { GameActions } from "components/game/GameActions";

const Game = () => {
  let { gameId } = useParams();
  const { etherHelper } = useContext(EtherContext);
  const { address } = useAccount();
  const { priceFeed } = usePriceProvider();

  const { game, reloadGame } = GameProvider(
    null,
    parseInt(gameId ? gameId : "0"),
    etherHelper
  );
  const { isConnected } = useAccount();

  useEffect(() => {
    if (!game || game == null) {
      reloadGame();
    }
  }, [game, etherHelper]);

  return (
    <PriceFeedContext.Provider value={{ priceFeed }}>
      <GameContext.Provider value={{ game, reloadGame }}>
        <Card mb={8} type="special">
          {game?.currentRound.currentBalance != undefined ? (
            <VStack justifySelf="center">
              <Heading size="lg">Jackpot</Heading>
              <Heading>
                <Flex gap="1">
                  {formatMoney(
                    EtherHelper.fromWei(game?.currentRound.currentBalance)
                  )}
                  <AvaxIcon fontSize="3rem" />
                </Flex>
              </Heading>
              <PriceConversion
                value={game?.currentRound.currentBalance}
              ></PriceConversion>
              <Flex
                gap="1"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                fontSize="lg"
              >
                <Text>Round {game?.currentRound.roundId}</Text>
                <Text>/</Text>
                <Text>
                  {game.maxRounds > 0 ? game.maxRounds : <BiInfinite />}
                </Text>
              </Flex>
            </VStack>
          ) : (
            <VStack w="full">
              <Skeleton height="20px" w="160px" />
              <Skeleton height="35px" w="200px" />
              <Skeleton height="12px" w="130px" />
            </VStack>
          )}
        </Card>

        <MarqueeGameInfos game={game}></MarqueeGameInfos>
        <AnimatePresence initial={false}>
          {game && (
            <>
              <Fieldset
                as={motion.div}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition="3s"
                my={10}
                title="Actions"
                position="relative"
              >
                {isConnected ? (
                  <GameActions></GameActions>
                ) : (
                  <NotConnectedWallet></NotConnectedWallet>
                )}
              </Fieldset>
            </>
          )}
        </AnimatePresence>
        <GameDetails></GameDetails>

        {game && game.currentRound.prevRoundId > 0 && (
          <Card mt={10} type="special">
            <RoundList></RoundList>
          </Card>
        )}
      </GameContext.Provider>
    </PriceFeedContext.Provider>
  );
};

Game.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>;
};
export default Game;
