import { Box, Flex } from "@chakra-ui/react";
import style from "./loading.module.scss";
export const Loading = () => {
  return (
    <Flex justifyContent="center" w="full">
      <Box className={style.box} position="relative">
        <Box className={style.cube}></Box>
        <Box className={style.cube}></Box>
        <Box className={style.cube}></Box>
        <Box className={style.cube}></Box>
      </Box>
    </Flex>
  );
};
