// src/components/layout.tsx
import { ReactNode, useContext } from "react";
import { Box, Container, Flex, Heading, Text } from "@chakra-ui/react";
import TopBar from "./common/TopBar/TopBar";
import { motion } from "framer-motion";
import { Card } from "./common/Card/Card";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount } from "wagmi";
import { EtherContext, useProvider } from "services/etherProvider";
import TrollBox from "./common/TrollBox";

type Props = {
  children: ReactNode;
};

export function Layout({
  children,
  isInfo,
  ...rest
}: {
  children: ReactNode;
  isInfo?: boolean;
}) {
  return (
    <div>
      <EtherContext.Provider value={useProvider()}>
        <TopBar></TopBar>
        <Container
          maxW="container.xl"
          py="8"
          minH="80vh"
          position="relative"
          zIndex="0"
        >
          {children}
        </Container>

        <TrollBox></TrollBox>
      </EtherContext.Provider>
    </div>
  );
}
