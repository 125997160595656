import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Input,
  FormControl,
  FormLabel,
  Tooltip,
  InputLeftElement,
  InputGroup,
  FormErrorMessage,
  Icon,
  Flex,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { Button } from "components/common/Button/Button";
import { FiFile } from "react-icons/fi";
import { StorageGames, StorageHandler } from "services/storageGamesProvider";
import { BiExit, BiImport } from "react-icons/bi";
export const ImportBackup = ({
  starred,
  played,
  ...rest
}: {
  starred: StorageGames;
  played: StorageGames;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [file, setFile] = useState<File | null>(null);
  const [invalid, setInvalid] = useState(false);

  const inputRef = useRef<any>(null);

  return (
    <>
      <Tooltip
        hasArrow
        placement="top"
        label="Import and replace user information saved on this pc"
      >
        <Button variant="outline" colorScheme="blue" onClick={onOpen}>
          <BiImport />
        </Button>
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onOpen} isCentered>
        <ModalOverlay />

        <ModalContent>
          <Flex
            position="absolute"
            right="0"
            justifyContent="flex-end"
            p="3"
            gap="3"
          >
            <Button onClick={onClose}>
              <BiExit></BiExit>
            </Button>
          </Flex>

          <ModalHeader>Import backup</ModalHeader>
          <ModalBody>
            <Text my="4">
              This action will import your saved data and replace the current (
              be sure that you haved backed up the current state of your data
              before!!!)
            </Text>
            <FormControl isInvalid={invalid} isRequired>
              <FormLabel htmlFor="writeUpFile">Import File</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Icon as={FiFile} />
                </InputLeftElement>
                <input
                  type="file"
                  onChange={(e) =>
                    e.target.files !== null
                      ? setFile(e.target.files[0])
                      : setFile(null)
                  }
                  accept={"document/text"}
                  name="file"
                  ref={inputRef}
                  style={{ display: "none" }}
                />
                <Input
                  value={file != null ? file.name : ""}
                  placeholder={"Your backed up file ..."}
                  onClick={() =>
                    inputRef.current !== null && inputRef.current.click()
                  }
                  // onChange={(e) => {}}
                  readOnly={true}
                />
              </InputGroup>
              <FormErrorMessage>{invalid}</FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              onClick={() => {
                file !== null &&
                  StorageHandler.restoreBackup(file, { starred, played });
                onClose();
              }}
              variant="solid"
              colorScheme="blue"
            >
              Import
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
